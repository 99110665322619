@import '../../../../Styles/variables';

.Handle {
  width: $handle-width;
  height: $handle-height;
  border-radius: 50%;
  backface-visibility: hidden;
  cursor: pointer;
  
  &_pageIndicator {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    z-index: 100;
    backface-visibility: hidden;
  }

  &__LeftArrow,
  &__RightArrow {
    display: block;
    position: absolute;
    top: calc(50% + 1px);
    height: 1rem;
    width: 1rem;
    opacity: 0;
    transition: all 300ms ease-out;
    backface-visibility: hidden;
    filter: invert(1);
  
    // 450px x 900px
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      display: none;
    }

    &_arrow {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5rem;
      height: 5rem;
      padding: 2rem;
    }
  }
  
  &__LeftArrow {
    left: 5%;
    transform: translate(0, -50%);
  }
  
  &__RightArrow {
    right: 5%;
    transform: translate(0, -50%) rotateZ(180deg);
  }

  &__Current,
  &__Slash,
  &__Total {
    display: block;
    position: absolute;
    transition: all 300ms ease-out;
    backface-visibility: hidden;
    font-weight: bold;
  
    // 450px x 900px
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      display: none;
    }
  }
  
  &__Current {
    font-size: $medium-font-size;
    bottom: 5%;
    left: -.75rem;
  }
  
  &__Slash {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    background-color: #000;
    height: 4.5rem;
  }
  
  &__Total {
    font-size: $smaller-font-size;
    bottom: 5%;
    right: -.25rem;
  }

  &__Mobile {
    display: none;
  }

  // 450px x 900px
  @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
    // MOBILE 
    &__Mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 2%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 31%;
      height: 4rem;
      z-index: 100;
    }
    
    &__MobilePageNum {
      font-size: $small-font-size;
      font-weight: bold;
    }
    
    &__MobileLeftArrow, 
    &__MobileRightArrow {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6.75rem;
      height: 6.75rem;
      padding: 2.25rem;
      filter: invert(1);
      top: 50%;
      bottom: 0;
      
      &_arrow {
        width: 100%;
        height: 100%;
      }
    }
  
    &__MobileLeftArrow {
      left: 0;
      transform: translateY(-53%);  
    }
    
    &__MobileRightArrow {
      right: 0;
      transform: rotateZ(180deg) translateY(53%);
    }
  }
}
