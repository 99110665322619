@import '../../../../Styles/variables';

.ContactLink {
  position: relative;
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  text-decoration: none;
  backface-visibility: hidden;

  // 350px x 600px
  @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
    width: 3rem;
    height: 3rem;
  }
  
  &:hover &__Image {
    filter: drop-shadow(0 0 1rem rgba($blue1, .8));
  }
  
  &__Image {
    display: block;
    width: 100%;
    height: 100%;
    transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    backface-visibility: hidden;
  }

  &__Name {
    font-size: $normal-font-size;
    text-align: center;
    text-transform: capitalize;
    color: $light-gray1;
    margin-top: 1.25rem;
    opacity: 0;
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    backface-visibility: hidden;

    // 1400px x 1100px
    // 1100px x 1400px
    // 450px x 900px
    @media only screen and (max-width: 87.5em) and (max-height: 68.75em) and (min-resolution: 150dpi),
    only screen and (max-width: 68.75em) and (max-height: 87.5em) and (min-resolution: 150dpi),
    only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -50%);
    }
    
    // 350px x 600px
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      display: none;
    }
  }

  &:hover &__Name {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}