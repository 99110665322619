@font-face {
  font-family: 'Alcubierre';
  src: local("Alcubierre"), url(/static/media/Alcubierre.1bb7d710.otf);
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Code';
  src: local("Code Bold"), url(/static/media/Code-Bold.f6a87fae.otf);
  font-weight: bold;
  font-style: bold; }

@font-face {
  font-family: 'Code';
  src: local("Code Light"), url(/static/media/Code-Light.e6d526de.otf);
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Blanch';
  src: local("Blanch"), url(/static/media/BLANCH_CONDENSED.ab53447b.otf);
  font-weight: 400; }

@font-face {
  font-family: 'Blanch';
  src: local("Blanch Light"), url(/static/media/BLANCH_CONDENSED_LIGHT.ff8ba422.otf);
  font-weight: 300; }

@font-face {
  font-family: 'Blanch Caps';
  src: local("Blanch Caps"), url(/static/media/BLANCH_CAPS.868b48e8.otf);
  font-weight: 400; }

@font-face {
  font-family: 'Blanch Caps';
  src: local("Blanch Caps Light"), url(/static/media/BLANCH_CAPS_LIGHT.797c8853.otf);
  font-weight: 300; }

@font-face {
  font-family: 'Blanch Caps Inline';
  src: local("Blanch Caps Inline"), url(/static/media/BLANCH_CAPS_INLINE.465f11fb.otf);
  font-weight: 400; }

@font-face {
  font-family: 'Blanch Inline';
  src: local("Blanch Inline"), url(/static/media/BLANCH_CONDENSED_INLINE.5a2f7055.otf);
  font-weight: 400; }

@font-face {
  font-family: 'Mohave';
  src: local("Mohave Light"), url(/static/media/Mohave-Light.546dd446.otf);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Mohave';
  src: local("Mohave Medium"), url(/static/media/Mohave-Medium.2dca0f26.otf);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Mohave';
  src: local("Mohave Bold"), url(/static/media/Mohave-Bold.c3d4e95c.otf);
  font-weight: 600;
  font-style: bold; }

html {
  font-size: 62.5%; }
  @media only screen and (max-width: 81.25em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 62.5em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 50em) {
    html {
      font-size: 46.88%; } }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  line-height: 1; }

body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-family: 'Alcubierre', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.Spinner_Spinner__3GN7B {
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: Spinner_spin__3uiyD 5s infinite linear;
          animation: Spinner_spin__3uiyD 5s infinite linear; }
  .Spinner_Spinner__3GN7B, .Spinner_Spinner__Wrapper__ul8pD {
    width: 22rem;
    height: 21rem;
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .Spinner_Spinner__Wrapper__ul8pD {
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0); }
  .Spinner_Spinner__Circle__1epEz {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .Spinner_Spinner__Circle_inner__335j_ {
      width: 100%;
      height: 100%;
      background-clip: padding;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden; }
      .Spinner_Spinner__Circle_inner__335j_::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border-right: none;
        border-top: none;
        -webkit-animation: Spinner_neon__1IMAY 20000ms ease-in-out infinite;
                animation: Spinner_neon__1IMAY 20000ms ease-in-out infinite;
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden; }

@-webkit-keyframes Spinner_spin__3uiyD {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes Spinner_spin__3uiyD {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.Spinner_Spinner__Circle__1epEz:nth-of-type(0) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.Spinner_Spinner__Circle_inner__335j_:nth-of-type(0) {
  -webkit-animation: Spinner_spin__3uiyD 2s infinite linear;
          animation: Spinner_spin__3uiyD 2s infinite linear; }

.Spinner_Spinner__Circle__1epEz:nth-of-type(1) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.Spinner_Spinner__Circle_inner__335j_:nth-of-type(1) {
  -webkit-animation: Spinner_spin__3uiyD 2s infinite linear;
          animation: Spinner_spin__3uiyD 2s infinite linear; }

.Spinner_Spinner__Circle__1epEz:nth-of-type(2) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.Spinner_Spinner__Circle_inner__335j_:nth-of-type(2) {
  -webkit-animation: Spinner_spin__3uiyD 2s infinite linear;
          animation: Spinner_spin__3uiyD 2s infinite linear; }

@-webkit-keyframes Spinner_neon__1IMAY {
  0%,
  12.5% {
    border: 5px solid rgba(255, 0, 255, 0.7);
    box-shadow: inset 0px 0px 10px rgba(255, 0, 255, 0.15); }
  25%,
  37.5% {
    border: 5px solid rgba(57, 255, 20, 0.7);
    box-shadow: inset 0px 0px 10px rgba(57, 255, 20, 0.15); }
  50%,
  62.5% {
    border: 5px solid rgba(247, 255, 26, 0.7);
    box-shadow: inset 0px 0px 10px rgba(247, 255, 26, 0.15); }
  75%,
  87.5% {
    border: 5px solid rgba(0, 254, 255, 0.7);
    box-shadow: inset 0px 0px 10px rgba(0, 254, 255, 0.15); }
  100% {
    border: 5px solid rgba(255, 0, 255, 0.7);
    box-shadow: inset 0px 0px 10px rgba(255, 0, 255, 0.15); } }

@keyframes Spinner_neon__1IMAY {
  0%,
  12.5% {
    border: 5px solid rgba(255, 0, 255, 0.7);
    box-shadow: inset 0px 0px 10px rgba(255, 0, 255, 0.15); }
  25%,
  37.5% {
    border: 5px solid rgba(57, 255, 20, 0.7);
    box-shadow: inset 0px 0px 10px rgba(57, 255, 20, 0.15); }
  50%,
  62.5% {
    border: 5px solid rgba(247, 255, 26, 0.7);
    box-shadow: inset 0px 0px 10px rgba(247, 255, 26, 0.15); }
  75%,
  87.5% {
    border: 5px solid rgba(0, 254, 255, 0.7);
    box-shadow: inset 0px 0px 10px rgba(0, 254, 255, 0.15); }
  100% {
    border: 5px solid rgba(255, 0, 255, 0.7);
    box-shadow: inset 0px 0px 10px rgba(255, 0, 255, 0.15); } }

.ProgressLogo_ProgressLogo__3oevW {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: all 500ms ease-out; }
  .ProgressLogo_ProgressLogo__Image__2fxez {
    display: block;
    width: 100%;
    height: auto;
    -webkit-animation: ProgressLogo_neonShadow__2Sz8I 20000ms ease-in-out infinite;
            animation: ProgressLogo_neonShadow__2Sz8I 20000ms ease-in-out infinite;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .ProgressLogo_ProgressLogo__ContactForm__205BL {
    width: 14rem; }
  .ProgressLogo_ProgressLogo__ProgressBar__OL1Kf {
    width: 13rem;
    opacity: 0; }

@-webkit-keyframes ProgressLogo_neonShadow__2Sz8I {
  0% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); }
  12.5% {
    -webkit-filter: drop-shadow(0 0 25px #ff00ff);
            filter: drop-shadow(0 0 25px #ff00ff); }
  25% {
    -webkit-filter: drop-shadow(0 0 0 #39ff14);
            filter: drop-shadow(0 0 0 #39ff14); }
  37.5% {
    -webkit-filter: drop-shadow(0 0 25px #39ff14);
            filter: drop-shadow(0 0 25px #39ff14); }
  50% {
    -webkit-filter: drop-shadow(0 0 0 #F7FF1A);
            filter: drop-shadow(0 0 0 #F7FF1A); }
  62.5% {
    -webkit-filter: drop-shadow(0 0 25px #F7FF1A);
            filter: drop-shadow(0 0 25px #F7FF1A); }
  75% {
    -webkit-filter: drop-shadow(0 0 0 #00FEFF);
            filter: drop-shadow(0 0 0 #00FEFF); }
  87.5% {
    -webkit-filter: drop-shadow(0 0 25px #00FEFF);
            filter: drop-shadow(0 0 25px #00FEFF); }
  100% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); } }

@keyframes ProgressLogo_neonShadow__2Sz8I {
  0% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); }
  12.5% {
    -webkit-filter: drop-shadow(0 0 25px #ff00ff);
            filter: drop-shadow(0 0 25px #ff00ff); }
  25% {
    -webkit-filter: drop-shadow(0 0 0 #39ff14);
            filter: drop-shadow(0 0 0 #39ff14); }
  37.5% {
    -webkit-filter: drop-shadow(0 0 25px #39ff14);
            filter: drop-shadow(0 0 25px #39ff14); }
  50% {
    -webkit-filter: drop-shadow(0 0 0 #F7FF1A);
            filter: drop-shadow(0 0 0 #F7FF1A); }
  62.5% {
    -webkit-filter: drop-shadow(0 0 25px #F7FF1A);
            filter: drop-shadow(0 0 25px #F7FF1A); }
  75% {
    -webkit-filter: drop-shadow(0 0 0 #00FEFF);
            filter: drop-shadow(0 0 0 #00FEFF); }
  87.5% {
    -webkit-filter: drop-shadow(0 0 25px #00FEFF);
            filter: drop-shadow(0 0 25px #00FEFF); }
  100% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); } }

.ProgressBar_ProgressBar__2OF0i {
  display: block;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background-image: radial-gradient(circle at 50% 50%, #000000 25%, #0f0f0f 70%, #2f2f2f);
  transition: all 750ms ease-in; }
  .ProgressBar_ProgressBar__Percent__3ejlI {
    font-size: 3.5rem;
    letter-spacing: 0.15rem;
    margin-left: -0.075rem;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .ProgressBar_ProgressBar__Logo__XNe0R {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: block;
    width: 13rem;
    height: auto;
    -webkit-animation: ProgressBar_neonShadow__3mtcD 20000ms ease-in-out infinite;
            animation: ProgressBar_neonShadow__3mtcD 20000ms ease-in-out infinite;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    opacity: 0; }
  .ProgressBar_ProgressBar__Bar__1gUck {
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%) scaleX(0);
            transform: translateY(-50%) scaleX(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    z-index: -10;
    border-radius: 10px;
    transition: all 300ms easeout;
    -webkit-animation: ProgressBar_barShadow__35-lb 20000ms ease-in-out infinite;
            animation: ProgressBar_barShadow__35-lb 20000ms ease-in-out infinite; }
  .ProgressBar_ProgressBar_exit__2pINi {
    opacity: 1; }
  .ProgressBar_ProgressBar_exit_active__2i1mX {
    opacity: 0; }
  .ProgressBar_ProgressBar_exit_done__1LqwS {
    opacity: 0; }

@-webkit-keyframes ProgressBar_barShadow__35-lb {
  0% {
    box-shadow: 0 0 0 #ff00ff;
    background-color: #ff00ff; }
  12.5% {
    box-shadow: 0 0 20px #ff00ff;
    background-color: #ff00ff; }
  25% {
    box-shadow: 0 0 0 #39ff14;
    background-color: #39ff14; }
  37.5% {
    box-shadow: 0 0 20px #39ff14;
    background-color: #39ff14; }
  50% {
    box-shadow: 0 0 0 #F7FF1A;
    background-color: #F7FF1A; }
  62.5% {
    box-shadow: 0 0 20px #F7FF1A;
    background-color: #F7FF1A; }
  75% {
    box-shadow: 0 0 0 #00FEFF;
    background-color: #00FEFF; }
  87.5% {
    box-shadow: 0 0 20px #00FEFF;
    background-color: #00FEFF; }
  100% {
    box-shadow: 0 0 0 #ff00ff;
    background-color: #ff00ff; } }

@keyframes ProgressBar_barShadow__35-lb {
  0% {
    box-shadow: 0 0 0 #ff00ff;
    background-color: #ff00ff; }
  12.5% {
    box-shadow: 0 0 20px #ff00ff;
    background-color: #ff00ff; }
  25% {
    box-shadow: 0 0 0 #39ff14;
    background-color: #39ff14; }
  37.5% {
    box-shadow: 0 0 20px #39ff14;
    background-color: #39ff14; }
  50% {
    box-shadow: 0 0 0 #F7FF1A;
    background-color: #F7FF1A; }
  62.5% {
    box-shadow: 0 0 20px #F7FF1A;
    background-color: #F7FF1A; }
  75% {
    box-shadow: 0 0 0 #00FEFF;
    background-color: #00FEFF; }
  87.5% {
    box-shadow: 0 0 20px #00FEFF;
    background-color: #00FEFF; }
  100% {
    box-shadow: 0 0 0 #ff00ff;
    background-color: #ff00ff; } }

@-webkit-keyframes ProgressBar_neonShadow__3mtcD {
  0% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); }
  12.5% {
    -webkit-filter: drop-shadow(0 0 25px #ff00ff);
            filter: drop-shadow(0 0 25px #ff00ff); }
  25% {
    -webkit-filter: drop-shadow(0 0 0 #39ff14);
            filter: drop-shadow(0 0 0 #39ff14); }
  37.5% {
    -webkit-filter: drop-shadow(0 0 25px #39ff14);
            filter: drop-shadow(0 0 25px #39ff14); }
  50% {
    -webkit-filter: drop-shadow(0 0 0 #F7FF1A);
            filter: drop-shadow(0 0 0 #F7FF1A); }
  62.5% {
    -webkit-filter: drop-shadow(0 0 25px #F7FF1A);
            filter: drop-shadow(0 0 25px #F7FF1A); }
  75% {
    -webkit-filter: drop-shadow(0 0 0 #00FEFF);
            filter: drop-shadow(0 0 0 #00FEFF); }
  87.5% {
    -webkit-filter: drop-shadow(0 0 25px #00FEFF);
            filter: drop-shadow(0 0 25px #00FEFF); }
  100% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); } }

@keyframes ProgressBar_neonShadow__3mtcD {
  0% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); }
  12.5% {
    -webkit-filter: drop-shadow(0 0 25px #ff00ff);
            filter: drop-shadow(0 0 25px #ff00ff); }
  25% {
    -webkit-filter: drop-shadow(0 0 0 #39ff14);
            filter: drop-shadow(0 0 0 #39ff14); }
  37.5% {
    -webkit-filter: drop-shadow(0 0 25px #39ff14);
            filter: drop-shadow(0 0 25px #39ff14); }
  50% {
    -webkit-filter: drop-shadow(0 0 0 #F7FF1A);
            filter: drop-shadow(0 0 0 #F7FF1A); }
  62.5% {
    -webkit-filter: drop-shadow(0 0 25px #F7FF1A);
            filter: drop-shadow(0 0 25px #F7FF1A); }
  75% {
    -webkit-filter: drop-shadow(0 0 0 #00FEFF);
            filter: drop-shadow(0 0 0 #00FEFF); }
  87.5% {
    -webkit-filter: drop-shadow(0 0 25px #00FEFF);
            filter: drop-shadow(0 0 25px #00FEFF); }
  100% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); } }

.ThreeJsBackground_ThreeJsBackground__3Ly4k {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -10; }

.NavItem_NavItem__12BfE {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding: 5rem 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  @media only screen and (max-width: 43.75em) {
    .NavItem_NavItem__12BfE {
      padding: 3.5rem 0; } }
  @media only screen and (max-height: 37.5em) {
    .NavItem_NavItem__12BfE {
      padding: 2.5rem; } }
  @media only screen and (max-height: 25em) {
    .NavItem_NavItem__12BfE {
      padding: 2rem; } }
  @media only screen and (max-width: 25em) and (max-height: 37.5em) {
    .NavItem_NavItem__12BfE {
      padding: 3rem; } }
  .NavItem_NavItem__TextWrapper__2DGbD {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    width: 100%; }
  .NavItem_NavItem__Text__Z0HJy {
    position: relative;
    color: #ccc;
    font-size: 7rem;
    font-family: 'Mohave';
    font-weight: 300;
    letter-spacing: 1rem;
    -webkit-transform: scaleY(1.25);
            transform: scaleY(1.25);
    transition: all 400ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    @media only screen and (max-width: 25em) and (max-height: 43.75em) {
      .NavItem_NavItem__Text__Z0HJy {
        font-size: 5rem; } }
    @media only screen and (max-width: 18.75em) {
      .NavItem_NavItem__Text__Z0HJy {
        font-size: 4rem; } }
    @media only screen and (max-height: 37.5em) {
      .NavItem_NavItem__Text__Z0HJy {
        font-size: 5rem; } }
    .NavItem_NavItem__Text__Z0HJy::before, .NavItem_NavItem__Text__Z0HJy::after {
      content: '';
      display: block;
      position: absolute;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      left: -12.5%;
      -webkit-transform-origin: left;
              transform-origin: left;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      width: 0;
      height: 5px;
      background-color: rgba(222, 115, 255, 0.4);
      z-index: -20;
      transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86); }
    .NavItem_NavItem__Text__Z0HJy::before {
      top: 40%; }
    .NavItem_NavItem__Text__Z0HJy::after {
      top: 60%; }
  .NavItem_NavItem__Number__19K7u {
    font-size: 14rem;
    font-family: 'Code';
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    color: rgba(64, 64, 64, 0.1);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: all 200ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    @media only screen and (max-width: 25em) and (max-height: 43.75em) {
      .NavItem_NavItem__Number__19K7u {
        font-size: 12rem; } }
    @media only screen and (max-height: 37.5em) {
      .NavItem_NavItem__Number__19K7u {
        font-size: 9rem; } }
  .NavItem_NavItem__12BfE:hover .NavItem_NavItem__Text__Z0HJy::before,
  .NavItem_NavItem__12BfE:hover .NavItem_NavItem__Text__Z0HJy::after {
    width: 125%; }
  .NavItem_NavItem__12BfE:hover .NavItem_NavItem__Text__Z0HJy {
    color: white !important; }
  .NavItem_NavItem__12BfE:hover .NavItem_NavItem__Number__19K7u {
    color: rgba(204, 204, 204, 0.2); }

.NavItems_NavItems__2AQ6X {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100000000; }
  .NavItems_NavItems__2AQ6X > * {
    opacity: 0;
    visibility: hidden; }
  @media screen and (min-width: 28.13em) and (min-height: 56.25em) {
    .NavItems_NavItems__2AQ6X:hover > * > *:first-child > *:first-child {
      color: rgba(19, 19, 19, 0.5); } }

.Hamburger_Burger__3a2Xf {
  display: block;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  padding: 1rem;
  width: 10rem;
  position: absolute;
  top: 50%;
  left: 2rem;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 5000000;
  border-radius: 50%;
  cursor: pointer; }
  @media only screen and (max-width: 46.87em) {
    .Hamburger_Burger__3a2Xf {
      left: 50%;
      top: 0rem;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0); } }
  .Hamburger_Burger__TopWrapper__3ma83, .Hamburger_Burger__MidWrapper__1wdkZ, .Hamburger_Burger__BotWrapper__1S2Ty {
    display: block;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    overflow: hidden;
    margin: 2rem 0; }
    @media only screen and (max-width: 25em) and (max-height: 43.75em) {
      .Hamburger_Burger__TopWrapper__3ma83, .Hamburger_Burger__MidWrapper__1wdkZ, .Hamburger_Burger__BotWrapper__1S2Ty {
        margin: 1.65rem 0; } }
    @media only screen and (max-width: 25em) and (max-height: 37.5em) {
      .Hamburger_Burger__TopWrapper__3ma83, .Hamburger_Burger__MidWrapper__1wdkZ, .Hamburger_Burger__BotWrapper__1S2Ty {
        margin: 1.75rem 0; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Hamburger_Burger__TopWrapper__3ma83, .Hamburger_Burger__MidWrapper__1wdkZ, .Hamburger_Burger__BotWrapper__1S2Ty {
        margin: 1.5rem 0; } }
  .Hamburger_Burger__Top__35dOt, .Hamburger_Burger__Mid__3VNqD, .Hamburger_Burger__Bot__1H6Cc {
    display: block;
    width: 8rem;
    height: 2px;
    background-color: #ccc;
    transition: all 800ms cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .Hamburger_Burger__Ring__33YCI {
    display: block;
    width: 1.75rem;
    height: 1.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: 2px solid #ccc;
    border-radius: 50%;
    background-color: transparent !important;
    opacity: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: 1100ms cubic-bezier(0.075, 0.82, 0.165, 1) -webkit-filter;
    transition: 1100ms cubic-bezier(0.075, 0.82, 0.165, 1) filter;
    transition: 1100ms cubic-bezier(0.075, 0.82, 0.165, 1) filter, 1100ms cubic-bezier(0.075, 0.82, 0.165, 1) -webkit-filter; }
  .Hamburger_Burger__BgCircle__3HWxl {
    display: block;
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -10;
    border-radius: 50%;
    opacity: 0.15; }
  .Hamburger_Burger__3a2Xf:hover .Hamburger_Burger__Top__35dOt,
  .Hamburger_Burger__3a2Xf:hover .Hamburger_Burger__Bot__1H6Cc {
    width: 3rem;
    background-color: #fff; }
  .Hamburger_Burger__3a2Xf:hover .Hamburger_Burger__Mid__3VNqD {
    background-color: #fff; }

.Hamburger_open__2Z6WN:hover .Hamburger_Burger__Mid__3VNqD {
  width: 6rem;
  -webkit-transform: translateX(1rem);
          transform: translateX(1rem); }

.Hamburger_open__2Z6WN .Hamburger_Burger__Top__35dOt,
.Hamburger_open__2Z6WN .Hamburger_Burger__Bot__1H6Cc {
  width: 3rem;
  -webkit-transform: translateX(-1rem);
          transform: translateX(-1rem);
  opacity: 0; }

.Hamburger_open__2Z6WN .Hamburger_Burger__Mid__3VNqD {
  width: 8rem; }

.Backdrop_Backdrop__2rE5X {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 1000000;
  transition: all 700ms ease-out 250ms;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }
  .Backdrop_Backdrop_enter__2-XVk {
    opacity: 0; }
  .Backdrop_Backdrop_enter_active__tD9no {
    opacity: 1; }
  .Backdrop_Backdrop_enter_done__1DA2J {
    opacity: 1; }
  .Backdrop_Backdrop_exit__2lQU2 {
    opacity: 1; }
  .Backdrop_Backdrop_exit_active__2vE7H {
    opacity: 0; }
  .Backdrop_Backdrop_exit_done__14gau {
    opacity: 0; }

.Navigation_Navigation__3ghKa {
  display: block;
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100); }

.LandscapeWarning_LandscapeWarning__2rGpc {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .LandscapeWarning_LandscapeWarning__Text__13WY1 {
    display: none; }
  .LandscapeWarning_LandscapeWarning__Image__et-Aw {
    width: 15rem;
    height: 15rem; }
    @media only screen and (max-width: 53.13em) and (max-height: 28.13em) {
      .LandscapeWarning_LandscapeWarning__Image__et-Aw {
        width: 11rem; } }
    @media only screen and (max-width: 46.88em) and (max-height: 28.13em) {
      .LandscapeWarning_LandscapeWarning__Image__et-Aw {
        width: 11rem; } }
    @media only screen and (max-width: 43.75em) and (max-height: 25em) {
      .LandscapeWarning_LandscapeWarning__Image__et-Aw {
        width: 10rem; } }
    @media only screen and (max-width: 37.5em) and (max-height: 21.88em) {
      .LandscapeWarning_LandscapeWarning__Image__et-Aw {
        width: 8rem;
        margin-top: -2rem; } }
  @media only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (min-resolution: 150dpi) {
    .LandscapeWarning_LandscapeWarning__2rGpc {
      opacity: 1;
      visibility: visible;
      transition: opacity 500ms ease-out; }
      .LandscapeWarning_LandscapeWarning__Text__13WY1 {
        font-size: 6rem;
        display: block; } }
    @media only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5625) and (max-width: 53.13em) and (max-height: 28.13em), only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (min-resolution: 150dpi) and (max-width: 53.13em) and (max-height: 28.13em) {
      .LandscapeWarning_LandscapeWarning__Text__13WY1 {
        font-size: 5rem; } }
    @media only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5625) and (max-width: 46.88em) and (max-height: 28.13em), only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (min-resolution: 150dpi) and (max-width: 46.88em) and (max-height: 28.13em) {
      .LandscapeWarning_LandscapeWarning__Text__13WY1 {
        font-size: 5rem; } }
    @media only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5625) and (max-width: 43.75em) and (max-height: 25em), only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (min-resolution: 150dpi) and (max-width: 43.75em) and (max-height: 25em) {
      .LandscapeWarning_LandscapeWarning__Text__13WY1 {
        font-size: 4rem; } }
    @media only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5625) and (max-width: 37.5em) and (max-height: 21.88em), only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (min-resolution: 150dpi) and (max-width: 37.5em) and (max-height: 21.88em) {
      .LandscapeWarning_LandscapeWarning__Text__13WY1 {
        font-size: 3.5rem; } }

.Layout_Layout__2NX-U {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  @media only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (min-resolution: 150dpi) {
    .Layout_Layout__2NX-U {
      display: none; } }
  .Layout_Layout__Main__Wbw0W {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-perspective: 80rem;
            perspective: 80rem; }

.Logo_Logo__2Y4u2 {
  -webkit-perspective: 60rem;
          perspective: 60rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: relative; }
  .Logo_Logo_landing__3hvCS {
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation: Logo_neonShadow__25ZKS 20000ms ease-in-out infinite;
            animation: Logo_neonShadow__25ZKS 20000ms ease-in-out infinite; }
    @media only screen and (max-width: 96.88em) and (max-height: 46.88em) {
      .Logo_Logo_landing__3hvCS {
        top: 42%; } }
    @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
      .Logo_Logo_landing__3hvCS {
        top: 47%; } }
    @media only screen and (max-width: 68.75em) and (max-height: 50em) {
      .Logo_Logo_landing__3hvCS {
        top: 41%; } }
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      .Logo_Logo_landing__3hvCS {
        top: 45%; } }
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      .Logo_Logo_landing__3hvCS {
        top: 43.5%; } }
    @media only screen and (max-width: 28.13em) and (max-height: 50em) {
      .Logo_Logo_landing__3hvCS {
        top: 41.5%; } }
    @media only screen and (max-width: 25em) and (max-height: 43.75em) {
      .Logo_Logo_landing__3hvCS {
        top: 42%; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Logo_Logo_landing__3hvCS {
        top: 45%; } }
  .Logo_Logo__Eyes__WPRQX {
    display: block;
    position: absolute;
    z-index: -10;
    -webkit-animation: Logo_neonEyes__3RihF 20000ms ease-in-out infinite;
            animation: Logo_neonEyes__3RihF 20000ms ease-in-out infinite; }
    .Logo_Logo__Eyes_landing__1xbOu {
      top: 45%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 15rem;
      height: 5rem; }
      @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
        .Logo_Logo__Eyes_landing__1xbOu {
          top: 44%;
          width: 10rem;
          height: 3rem; } }
  .Logo_Logo__Img__3Sw_A {
    display: block;
    width: 27.5rem;
    height: 27.5rem; }
    @media only screen and (max-width: 68.75em) and (max-height: 50em) {
      .Logo_Logo__Img__3Sw_A {
        width: 22rem;
        height: 22rem; } }
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      .Logo_Logo__Img__3Sw_A {
        width: 26rem;
        height: 26rem; } }
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      .Logo_Logo__Img__3Sw_A {
        width: 23rem;
        height: 23rem; } }
    @media only screen and (max-width: 25em) and (max-height: 43.75em) {
      .Logo_Logo__Img__3Sw_A {
        width: 21rem;
        height: 21rem; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Logo_Logo__Img__3Sw_A {
        width: 19.5rem;
        height: 19.5rem; } }

@-webkit-keyframes Logo_neonShadow__25ZKS {
  0% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); }
  12.5% {
    -webkit-filter: drop-shadow(0 0 45px #ff00ff);
            filter: drop-shadow(0 0 45px #ff00ff); }
  25% {
    -webkit-filter: drop-shadow(0 0 0 #39ff14);
            filter: drop-shadow(0 0 0 #39ff14); }
  37.5% {
    -webkit-filter: drop-shadow(0 0 45px #39ff14);
            filter: drop-shadow(0 0 45px #39ff14); }
  50% {
    -webkit-filter: drop-shadow(0 0 0 #F7FF1A);
            filter: drop-shadow(0 0 0 #F7FF1A); }
  62.5% {
    -webkit-filter: drop-shadow(0 0 45px #F7FF1A);
            filter: drop-shadow(0 0 45px #F7FF1A); }
  75% {
    -webkit-filter: drop-shadow(0 0 0 #00FEFF);
            filter: drop-shadow(0 0 0 #00FEFF); }
  87.5% {
    -webkit-filter: drop-shadow(0 0 45px #00FEFF);
            filter: drop-shadow(0 0 45px #00FEFF); }
  100% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); } }

@keyframes Logo_neonShadow__25ZKS {
  0% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); }
  12.5% {
    -webkit-filter: drop-shadow(0 0 45px #ff00ff);
            filter: drop-shadow(0 0 45px #ff00ff); }
  25% {
    -webkit-filter: drop-shadow(0 0 0 #39ff14);
            filter: drop-shadow(0 0 0 #39ff14); }
  37.5% {
    -webkit-filter: drop-shadow(0 0 45px #39ff14);
            filter: drop-shadow(0 0 45px #39ff14); }
  50% {
    -webkit-filter: drop-shadow(0 0 0 #F7FF1A);
            filter: drop-shadow(0 0 0 #F7FF1A); }
  62.5% {
    -webkit-filter: drop-shadow(0 0 45px #F7FF1A);
            filter: drop-shadow(0 0 45px #F7FF1A); }
  75% {
    -webkit-filter: drop-shadow(0 0 0 #00FEFF);
            filter: drop-shadow(0 0 0 #00FEFF); }
  87.5% {
    -webkit-filter: drop-shadow(0 0 45px #00FEFF);
            filter: drop-shadow(0 0 45px #00FEFF); }
  100% {
    -webkit-filter: drop-shadow(0 0 0 #ff00ff);
            filter: drop-shadow(0 0 0 #ff00ff); } }

@-webkit-keyframes Logo_neonEyes__3RihF {
  0%,
  12.5% {
    box-shadow: inset 0 0 1000px #ff00ff; }
  25%,
  37.5% {
    box-shadow: inset 0 0 1000px #39ff14; }
  50%,
  62.5% {
    box-shadow: inset 0 0 1000px #F7FF1A; }
  75%,
  87.5% {
    box-shadow: inset 0 0 1000px #00FEFF; }
  100% {
    box-shadow: inset 0 0 1000px #ff00ff; } }

@keyframes Logo_neonEyes__3RihF {
  0%,
  12.5% {
    box-shadow: inset 0 0 1000px #ff00ff; }
  25%,
  37.5% {
    box-shadow: inset 0 0 1000px #39ff14; }
  50%,
  62.5% {
    box-shadow: inset 0 0 1000px #F7FF1A; }
  75%,
  87.5% {
    box-shadow: inset 0 0 1000px #00FEFF; }
  100% {
    box-shadow: inset 0 0 1000px #ff00ff; } }

.TitleMain_TitleMain__3seUM {
  display: block;
  font-weight: 300;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: relative; }
  .TitleMain_TitleMain_landing__Lqd-0 {
    position: absolute;
    top: 63%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    text-align: center; }
    @media only screen and (max-width: 96.88em) and (max-height: 46.88em) {
      .TitleMain_TitleMain_landing__Lqd-0 {
        top: 65%; } }
    @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
      .TitleMain_TitleMain_landing__Lqd-0 {
        top: 58.5%; } }
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      .TitleMain_TitleMain_landing__Lqd-0 {
        top: 57%; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .TitleMain_TitleMain_landing__Lqd-0 {
        top: 62%; } }
  .TitleMain_TitleMain_portfolio__34c9d {
    font-family: 'Blanch Inline';
    width: -webkit-max-content;
    width: max-content; }
  .TitleMain_TitleMain_about__2eICM {
    font-family: 'Mohave';
    width: -webkit-max-content;
    width: max-content;
    text-transform: none; }
  .TitleMain_TitleMain_contact__3xBIH {
    width: -webkit-max-content;
    width: max-content;
    text-transform: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    @media only screen and (max-width: 80em) and (max-height: 34.68em) {
      .TitleMain_TitleMain_contact__3xBIH {
        bottom: 50%; } }
  .TitleMain_TitleMain__Letters__35cGL {
    display: block;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .TitleMain_TitleMain__Letters_landing__3_ZpW {
      color: #fff;
      font-size: 6rem;
      font-weight: 400;
      letter-spacing: 1.5rem;
      margin-left: 0.75rem; }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .TitleMain_TitleMain__Letters_landing__3_ZpW {
          font-size: 5rem; } }
      @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
        .TitleMain_TitleMain__Letters_landing__3_ZpW {
          font-size: 5rem; } }
      @media only screen and (max-width: 25em) and (max-height: 43.75em) {
        .TitleMain_TitleMain__Letters_landing__3_ZpW {
          font-size: 4.5rem; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .TitleMain_TitleMain__Letters_landing__3_ZpW {
          font-size: 4rem;
          letter-spacing: 1rem; } }
    .TitleMain_TitleMain__Letters_portfolio__3L4pS {
      font-size: 12rem;
      letter-spacing: 1.5rem;
      font-weight: 400;
      text-shadow: 0 0 6px #000; }
      @media only screen and (max-width: 90.63em) {
        .TitleMain_TitleMain__Letters_portfolio__3L4pS {
          font-size: 10rem; } }
      @media only screen and (max-width: 59.38em) {
        .TitleMain_TitleMain__Letters_portfolio__3L4pS {
          letter-spacing: 1rem; } }
      @media only screen and (max-width: 28.13em) {
        .TitleMain_TitleMain__Letters_portfolio__3L4pS {
          font-size: 10rem; } }
      @media only screen and (max-width: 25em) and (max-height: 43.75em) {
        .TitleMain_TitleMain__Letters_portfolio__3L4pS {
          font-size: 9rem; } }
      @media only screen and (max-width: 25em) and (max-height: 37.5em) {
        .TitleMain_TitleMain__Letters_portfolio__3L4pS {
          font-size: 7rem; } }
      @media only screen and (max-width: 21.88em) {
        .TitleMain_TitleMain__Letters_portfolio__3L4pS {
          font-size: 6rem; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .TitleMain_TitleMain__Letters_portfolio__3L4pS {
          font-size: 6rem; } }
    .TitleMain_TitleMain__Letters_about__1-g9K {
      color: #ccc;
      font-size: 9rem;
      letter-spacing: 0.5rem;
      font-weight: 300; }
      @media only screen and (max-width: 68.75em) and (max-height: 50em), only screen and (max-width: 50em) and (max-height: 68.75em) {
        .TitleMain_TitleMain__Letters_about__1-g9K {
          font-size: 7rem; } }
      @media only screen and (max-width: 37.5em) and (max-height: 21.88em) {
        .TitleMain_TitleMain__Letters_about__1-g9K {
          font-size: 3.5rem; } }
      @media only screen and (max-width: 25em) and (max-height: 53.13em), (max-width: 28.13em) and (max-height: 46.88em) {
        .TitleMain_TitleMain__Letters_about__1-g9K {
          font-size: 6rem; } }
      @media only screen and (max-width: 21.88em) and (max-height: 43.75em) {
        .TitleMain_TitleMain__Letters_about__1-g9K {
          font-size: 5rem; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .TitleMain_TitleMain__Letters_about__1-g9K {
          font-size: 4rem; } }
    .TitleMain_TitleMain__Letters_contact__1Qg7x {
      color: #fff;
      font-size: 4rem;
      letter-spacing: 0.25rem;
      font-weight: 300; }
      @media only screen and (max-width: 80em) and (max-height: 34.68em) {
        .TitleMain_TitleMain__Letters_contact__1Qg7x {
          font-size: 3rem; } }
  .TitleMain_TitleMain__Letter__CUr9q {
    display: inline-block;
    position: relative; }

.TitleSecondary_TitleSecondary__2ba3r {
  display: block;
  font-weight: 300;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .TitleSecondary_TitleSecondary_landing__3yVUd {
    position: absolute;
    top: 68%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    text-align: center; }
    @media only screen and (max-width: 96.88em) and (max-height: 46.88em) {
      .TitleSecondary_TitleSecondary_landing__3yVUd {
        top: 71.5%; } }
    @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
      .TitleSecondary_TitleSecondary_landing__3yVUd {
        top: 62%; } }
    @media only screen and (max-width: 68.75em) and (max-height: 50em) {
      .TitleSecondary_TitleSecondary_landing__3yVUd {
        top: 63%; } }
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      .TitleSecondary_TitleSecondary_landing__3yVUd {
        top: 61%; } }
    @media only screen and (max-width: 28.13em) and (max-height: 50em) {
      .TitleSecondary_TitleSecondary_landing__3yVUd {
        top: 61.5%; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .TitleSecondary_TitleSecondary_landing__3yVUd {
        top: 66%; } }
  .TitleSecondary_TitleSecondary_about__1yaXP {
    text-transform: none;
    width: -webkit-max-content;
    width: max-content;
    margin-bottom: 2rem; }
  .TitleSecondary_TitleSecondary_contact__32JdM {
    text-transform: none;
    width: -webkit-max-content;
    width: max-content;
    margin-right: 1rem; }
  .TitleSecondary_TitleSecondary__Letters__1nhyw {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .TitleSecondary_TitleSecondary__Letters_landing__2Su1p {
      color: #fff;
      font-size: 3rem;
      letter-spacing: 1rem;
      margin-left: 0.5rem;
      font-weight: 400; }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .TitleSecondary_TitleSecondary__Letters_landing__2Su1p {
          font-size: 2rem; } }
      @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
        .TitleSecondary_TitleSecondary__Letters_landing__2Su1p {
          font-size: 2rem; } }
      @media only screen and (max-width: 25em) and (max-height: 43.75em) {
        .TitleSecondary_TitleSecondary__Letters_landing__2Su1p {
          font-size: 1.9rem; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .TitleSecondary_TitleSecondary__Letters_landing__2Su1p {
          letter-spacing: 0.5rem; } }
    .TitleSecondary_TitleSecondary__Letters_about__11KGD {
      color: #ccc;
      font-size: 4rem;
      letter-spacing: 0.5rem;
      font-weight: 400; }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .TitleSecondary_TitleSecondary__Letters_about__11KGD {
          font-size: 3rem; } }
      @media only screen and (max-width: 50em) and (max-height: 65.63em) {
        .TitleSecondary_TitleSecondary__Letters_about__11KGD {
          font-size: 3rem;
          letter-spacing: 0.25rem; } }
      @media only screen and (max-width: 28.125em) and (max-height: 53.13em) {
        .TitleSecondary_TitleSecondary__Letters_about__11KGD {
          font-size: 3rem;
          letter-spacing: 0.25rem; } }
      @media only screen and (max-width: 25em) and (max-height: 53.13em), (max-width: 28.125em) and (max-height: 46.88em) {
        .TitleSecondary_TitleSecondary__Letters_about__11KGD {
          font-size: 3rem;
          letter-spacing: 0.25rem; } }
      @media only screen and (max-width: 21.88em) and (max-height: 43.75em) {
        .TitleSecondary_TitleSecondary__Letters_about__11KGD {
          font-size: 2rem; } }
    .TitleSecondary_TitleSecondary__Letters_contact__rkPer {
      color: #fff;
      font-size: 3.5rem;
      letter-spacing: 0.25rem;
      font-weight: 400; }
      @media only screen and (max-width: 80em) and (max-height: 34.68em) {
        .TitleSecondary_TitleSecondary__Letters_contact__rkPer {
          font-size: 3rem; } }
  .TitleSecondary_TitleSecondary__Letter__3X_x8 {
    position: relative;
    display: inline-block; }

.LandingBtn_LandingBtn__15TsL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  z-index: 1000; }
  @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
    .LandingBtn_LandingBtn__15TsL {
      bottom: -3rem; } }
  @media only screen and (max-width: 25em) and (max-height: 43.75em) {
    .LandingBtn_LandingBtn__15TsL {
      bottom: -5rem; } }
  .LandingBtn_LandingBtn__Link__25Uk6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-left: 0.25rem;
    color: inherit; }
  .LandingBtn_LandingBtn__15TsL:hover .LandingBtn_LandingBtn__Text___NRwx {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    @media only screen and (max-width: 87.5em) and (max-height: 68.75em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 87.5em) and (max-height: 68.75em) and (min-resolution: 150dpi), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (min-resolution: 150dpi) {
      .LandingBtn_LandingBtn__15TsL:hover .LandingBtn_LandingBtn__Text___NRwx {
        -webkit-transform: translate(0, -60%);
                transform: translate(0, -60%); } }
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      .LandingBtn_LandingBtn__15TsL:hover .LandingBtn_LandingBtn__Text___NRwx {
        -webkit-transform: translate(0, -70%);
                transform: translate(0, -70%); } }
  .LandingBtn_LandingBtn__Text___NRwx {
    display: block;
    position: relative;
    margin-bottom: 1.5rem;
    opacity: 0;
    transition: all 400ms ease-in 50ms;
    width: -webkit-max-content;
    width: max-content;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    color: #fff; }
    @media only screen and (max-width: 87.5em) and (max-height: 68.75em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 87.5em) and (max-height: 68.75em) and (min-resolution: 150dpi), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (min-resolution: 150dpi) {
      .LandingBtn_LandingBtn__Text___NRwx {
        opacity: 1;
        -webkit-transform: translate(0, -60%);
                transform: translate(0, -60%);
        font-size: 1.6rem; } }
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      .LandingBtn_LandingBtn__Text___NRwx {
        -webkit-transform: translate(0, -70%);
                transform: translate(0, -70%);
        margin-bottom: 2rem; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .LandingBtn_LandingBtn__Text___NRwx {
        font-size: 1.5rem; } }
  .LandingBtn_LandingBtn__Arrow__1cTHz {
    position: relative;
    width: 100px;
    height: 100px; }
    .LandingBtn_LandingBtn__Arrow__1cTHz span {
      display: block;
      width: 28px;
      height: 28px;
      border-bottom: 4px solid #ff00ff;
      border-right: 4px solid #ff00ff;
      border-radius: 4px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      margin: -10px auto;
      -webkit-animation: LandingBtn_animate__2hxs1 2.75s ease-in-out infinite, LandingBtn_neonColors__1tCRy 20000ms ease-in-out infinite;
              animation: LandingBtn_animate__2hxs1 2.75s ease-in-out infinite, LandingBtn_neonColors__1tCRy 20000ms ease-in-out infinite; }
      @media only screen and (max-width: 68.75em) and (max-height: 50em), only screen and (max-width: 50em) and (max-height: 68.75em) {
        .LandingBtn_LandingBtn__Arrow__1cTHz span {
          width: 22px;
          height: 22px; } }
      @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
        .LandingBtn_LandingBtn__Arrow__1cTHz span {
          width: 21px;
          height: 21px;
          -webkit-animation: LandingBtn_animateSmaller__k5l-q 2.75s ease-in-out infinite, LandingBtn_neonColors2px__17YqO 20000ms ease-in-out infinite;
                  animation: LandingBtn_animateSmaller__k5l-q 2.75s ease-in-out infinite, LandingBtn_neonColors2px__17YqO 20000ms ease-in-out infinite;
          margin: -11px auto; } }
      @media only screen and (max-width: 25em) and (max-height: 43.75em) {
        .LandingBtn_LandingBtn__Arrow__1cTHz span {
          width: 20px;
          height: 20px;
          -webkit-animation: LandingBtn_animateSmallest__2bwjL 2.75s ease-in-out infinite, LandingBtn_neonColors2px__17YqO 20000ms ease-in-out infinite;
                  animation: LandingBtn_animateSmallest__2bwjL 2.75s ease-in-out infinite, LandingBtn_neonColors2px__17YqO 20000ms ease-in-out infinite; } }
    .LandingBtn_LandingBtn__Arrow__1cTHz span:nth-child(2) {
      -webkit-animation-delay: -0.25s;
              animation-delay: -0.25s; }
    .LandingBtn_LandingBtn__Arrow__1cTHz span:nth-child(3) {
      -webkit-animation-delay: -0.45s;
              animation-delay: -0.45s; }

@-webkit-keyframes LandingBtn_animate__2hxs1 {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-15px, -15px);
            transform: rotate(45deg) translate(-15px, -15px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(15px, 15px);
            transform: rotate(45deg) translate(15px, 15px); } }

@keyframes LandingBtn_animate__2hxs1 {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-15px, -15px);
            transform: rotate(45deg) translate(-15px, -15px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(15px, 15px);
            transform: rotate(45deg) translate(15px, 15px); } }

@-webkit-keyframes LandingBtn_animateSmaller__k5l-q {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-11px, -11px);
            transform: rotate(45deg) translate(-11px, -11px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(11px, 11px);
            transform: rotate(45deg) translate(11px, 11px); } }

@keyframes LandingBtn_animateSmaller__k5l-q {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-11px, -11px);
            transform: rotate(45deg) translate(-11px, -11px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(11px, 11px);
            transform: rotate(45deg) translate(11px, 11px); } }

@-webkit-keyframes LandingBtn_animateSmallest__2bwjL {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-7.5px, -7.5px);
            transform: rotate(45deg) translate(-7.5px, -7.5px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(7.5px, 7.5px);
            transform: rotate(45deg) translate(7.5px, 7.5px); } }

@keyframes LandingBtn_animateSmallest__2bwjL {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-7.5px, -7.5px);
            transform: rotate(45deg) translate(-7.5px, -7.5px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(7.5px, 7.5px);
            transform: rotate(45deg) translate(7.5px, 7.5px); } }

@-webkit-keyframes LandingBtn_neonColors__1tCRy {
  0%,
  12.5% {
    border-bottom: 4px solid #ff00ff;
    border-right: 4px solid #ff00ff; }
  25%,
  37.5% {
    border-bottom: 4px solid #39ff14;
    border-right: 4px solid #39ff14; }
  50%,
  62.5% {
    border-bottom: 4px solid #F7FF1A;
    border-right: 4px solid #F7FF1A; }
  75%,
  87.5% {
    border-bottom: 4px solid #00FEFF;
    border-right: 4px solid #00FEFF; }
  100% {
    border-bottom: 4px solid #ff00ff;
    border-right: 4px solid #ff00ff; } }

@keyframes LandingBtn_neonColors__1tCRy {
  0%,
  12.5% {
    border-bottom: 4px solid #ff00ff;
    border-right: 4px solid #ff00ff; }
  25%,
  37.5% {
    border-bottom: 4px solid #39ff14;
    border-right: 4px solid #39ff14; }
  50%,
  62.5% {
    border-bottom: 4px solid #F7FF1A;
    border-right: 4px solid #F7FF1A; }
  75%,
  87.5% {
    border-bottom: 4px solid #00FEFF;
    border-right: 4px solid #00FEFF; }
  100% {
    border-bottom: 4px solid #ff00ff;
    border-right: 4px solid #ff00ff; } }

@-webkit-keyframes LandingBtn_neonColors2px__17YqO {
  0% {
    border-bottom: 2px solid #ff00ff;
    border-right: 2px solid #ff00ff; }
  25% {
    border-bottom: 2px solid #39ff14;
    border-right: 2px solid #39ff14; }
  50% {
    border-bottom: 2px solid #F7FF1A;
    border-right: 2px solid #F7FF1A; }
  75% {
    border-bottom: 2px solid #00FEFF;
    border-right: 2px solid #00FEFF; }
  100% {
    border-bottom: 2px solid #ff00ff;
    border-right: 2px solid #ff00ff; } }

@keyframes LandingBtn_neonColors2px__17YqO {
  0% {
    border-bottom: 2px solid #ff00ff;
    border-right: 2px solid #ff00ff; }
  25% {
    border-bottom: 2px solid #39ff14;
    border-right: 2px solid #39ff14; }
  50% {
    border-bottom: 2px solid #F7FF1A;
    border-right: 2px solid #F7FF1A; }
  75% {
    border-bottom: 2px solid #00FEFF;
    border-right: 2px solid #00FEFF; }
  100% {
    border-bottom: 2px solid #ff00ff;
    border-right: 2px solid #ff00ff; } }

.Landing_Landing__1vqJV {
  position: relative;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }
  .Landing_Landing__Details__3OPpT {
    height: 100%;
    width: 100%; }

.Description_Description__12y5G {
  display: block;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: relative; }
  .Description_Description__Letters__Jgplr {
    display: inline-block;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .Description_Description__Letters_portfolio__UY45d {
      font-size: 1.6rem;
      letter-spacing: 0.25rem;
      line-height: 1.3;
      font-weight: bold;
      text-shadow: 0 0 2px #000; }

.Role_Role__1toWm {
  display: block;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .Role_Role_portfolio__3KKNx {
    width: -webkit-max-content;
    width: max-content; }
  .Role_Role__Letters__16Qoj {
    display: inline-block;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .Role_Role__Letters_portfolio__qeV69 {
      text-transform: uppercase;
      font-size: 3rem;
      letter-spacing: 0.25rem;
      font-weight: bold;
      text-shadow: 0 0 1px #333; }
      @media only screen and (max-width: 90.63em) {
        .Role_Role__Letters_portfolio__qeV69 {
          font-size: 2rem; } }
      @media only screen and (max-width: 71.88em) {
        .Role_Role__Letters_portfolio__qeV69 {
          letter-spacing: 0.1rem; } }
      @media only screen and (max-width: 56.25em) {
        .Role_Role__Letters_portfolio__qeV69 {
          letter-spacing: 0; } }
      @media only screen and (max-width: 37.5em) and (max-height: 25em) {
        .Role_Role__Letters_portfolio__qeV69 {
          font-size: 2rem; } }

.Year_Year__1ACIN {
  display: block;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .Year_Year_portfolio__3HC3- {
    width: -webkit-max-content;
    width: max-content; }
  .Year_Year__Letters__3ojPs {
    display: inline-block;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .Year_Year__Letters_portfolio__2lvKf {
      color: #606060;
      font-size: 4rem;
      letter-spacing: 1rem;
      font-weight: bold;
      text-shadow: 0 0 1px #333; }
      @media only screen and (max-width: 90.63em) {
        .Year_Year__Letters_portfolio__2lvKf {
          font-size: 3rem; } }
      @media only screen and (max-width: 56.25em) {
        .Year_Year__Letters_portfolio__2lvKf {
          letter-spacing: 0.5rem; } }
      @media only screen and (max-width: 28.13em) {
        .Year_Year__Letters_portfolio__2lvKf {
          font-size: 2rem; } }
      @media only screen and (max-width: 37.5em) and (max-height: 25em) {
        .Year_Year__Letters_portfolio__2lvKf {
          font-size: 2rem; } }

.BgImage_BgImage__2_XeD {
  display: block;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  position: relative;
  width: 30rem;
  height: 30rem;
  -webkit-perspective: 30rem;
          perspective: 30rem;
  z-index: -50;
  pointer-events: none; }
  .BgImage_BgImage__Image__1cwJ2 {
    position: absolute;
    display: block;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    top: 0;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }
    .BgImage_BgImage__Image_1__uklpu {
      top: -5%;
      left: -30%;
      width: 30rem; }
      @media only screen and (max-width: 101.56em) {
        .BgImage_BgImage__Image_1__uklpu {
          width: 28rem;
          left: -20%; } }
      @media only screen and (max-width: 84.38em) {
        .BgImage_BgImage__Image_1__uklpu {
          left: -5%;
          top: 0; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .BgImage_BgImage__Image_1__uklpu {
          width: 24rem;
          top: 10%;
          left: 0; } }
      @media only screen and (max-width: 50em) and (max-height: 68.75em) {
        .BgImage_BgImage__Image_1__uklpu {
          width: 25rem;
          top: 5%;
          left: 2%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__Image_1__uklpu {
          width: 50rem;
          top: 25%;
          left: 50%;
          opacity: .13 !important; } }
      @media only screen and (max-width: 28.13em) and (max-height: 46.875em) {
        .BgImage_BgImage__Image_1__uklpu {
          width: 45rem;
          top: 35%; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .BgImage_BgImage__Image_1__uklpu {
          width: 45rem; } }
    .BgImage_BgImage__Image_2__2cMs- {
      left: -30%;
      width: 34rem; }
      @media only screen and (max-width: 101.56em) {
        .BgImage_BgImage__Image_2__2cMs- {
          width: 34rem;
          left: -20%; } }
      @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
        .BgImage_BgImage__Image_2__2cMs- {
          width: 30rem;
          top: 10%;
          left: -15%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .BgImage_BgImage__Image_2__2cMs- {
          width: 28rem;
          top: 10%;
          left: -10%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .BgImage_BgImage__Image_2__2cMs- {
          width: 27rem;
          top: 15%;
          left: -5%; } }
      @media only screen and (max-width: 50em) and (max-height: 68.75em) {
        .BgImage_BgImage__Image_2__2cMs- {
          width: 25rem;
          left: -3%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__Image_2__2cMs- {
          display: none; } }
    .BgImage_BgImage__Image_3__17AdU {
      left: -30%;
      width: 38rem; }
      @media only screen and (max-width: 101.56em) {
        .BgImage_BgImage__Image_3__17AdU {
          width: 38rem;
          left: -27%; } }
      @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
        .BgImage_BgImage__Image_3__17AdU {
          width: 30rem;
          top: 15%;
          left: -23%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .BgImage_BgImage__Image_3__17AdU {
          width: 32rem;
          top: 18%;
          left: -15%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .BgImage_BgImage__Image_3__17AdU {
          width: 26rem;
          top: 22%;
          left: -5%; } }
      @media only screen and (max-width: 50em) and (max-height: 68.75em) {
        .BgImage_BgImage__Image_3__17AdU {
          width: 26rem;
          top: 20%;
          left: -5%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__Image_3__17AdU {
          display: none; } }
    .BgImage_BgImage__Image_4__3tc_o {
      top: -18%;
      left: -20%;
      width: 49rem; }
      @media only screen and (max-width: 101.56em) {
        .BgImage_BgImage__Image_4__3tc_o {
          width: 48rem;
          left: -27%; } }
      @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
        .BgImage_BgImage__Image_4__3tc_o {
          width: 44rem;
          top: -20%;
          left: -5%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .BgImage_BgImage__Image_4__3tc_o {
          width: 38rem;
          top: 0;
          left: 0; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .BgImage_BgImage__Image_4__3tc_o {
          width: 34rem;
          left: 9%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__Image_4__3tc_o {
          width: 70rem;
          top: 30%;
          left: 10%;
          opacity: .13 !important; } }
      @media only screen and (max-width: 28.13em) and (max-height: 46.875em) {
        .BgImage_BgImage__Image_4__3tc_o {
          width: 65rem;
          top: 50%; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .BgImage_BgImage__Image_4__3tc_o {
          width: 60rem;
          left: 20%; } }
  .BgImage_BgImage__SubImage__3LeiM {
    display: block;
    position: absolute;
    -webkit-transform: translateZ(-100px);
            transform: translateZ(-100px);
    width: 70rem;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .BgImage_BgImage__SubImage_1__9eeOg {
      top: -75%;
      left: -90%;
      width: 65rem;
      opacity: 0.65; }
      @media only screen and (max-width: 101.56em) {
        .BgImage_BgImage__SubImage_1__9eeOg {
          width: 65rem;
          left: -70%; } }
      @media only screen and (max-width: 84.38em) {
        .BgImage_BgImage__SubImage_1__9eeOg {
          width: 60rem;
          top: -60%;
          left: -60%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .BgImage_BgImage__SubImage_1__9eeOg {
          width: 55rem;
          top: -50%;
          left: -55%; } }
      @media only screen and (max-width: 50em) and (max-height: 68.75em) {
        .BgImage_BgImage__SubImage_1__9eeOg {
          width: 59rem;
          left: -55%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__SubImage_1__9eeOg {
          display: none; } }
    .BgImage_BgImage__SubImage_2__MS8yG {
      top: -80%;
      left: -85%;
      width: 60rem; }
      @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
        .BgImage_BgImage__SubImage_2__MS8yG {
          width: 50rem;
          top: -50%;
          left: -70%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .BgImage_BgImage__SubImage_2__MS8yG {
          width: 52rem;
          top: -40%;
          left: -60%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .BgImage_BgImage__SubImage_2__MS8yG {
          width: 48rem;
          top: -30%;
          left: -55%; } }
      @media only screen and (max-width: 50em) and (max-height: 68.75em) {
        .BgImage_BgImage__SubImage_2__MS8yG {
          width: 48rem;
          top: -40%;
          left: -55%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__SubImage_2__MS8yG {
          width: 50rem;
          top: 30%;
          left: 35%;
          opacity: .13 !important;
          -webkit-transform: translateZ(0);
                  transform: translateZ(0); } }
      @media only screen and (max-width: 28.13em) and (max-height: 46.875em) {
        .BgImage_BgImage__SubImage_2__MS8yG {
          width: 45rem;
          left: 45%; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .BgImage_BgImage__SubImage_2__MS8yG {
          width: 40rem;
          top: 20%;
          left: 45%; } }
    .BgImage_BgImage__SubImage_3__361Yz {
      top: -80%;
      left: -105%;
      width: 70rem; }
      @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
        .BgImage_BgImage__SubImage_3__361Yz {
          width: 60rem;
          top: -50%;
          left: -85%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .BgImage_BgImage__SubImage_3__361Yz {
          width: 60rem;
          left: -75%;
          top: -50%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .BgImage_BgImage__SubImage_3__361Yz {
          width: 55rem;
          top: -40%;
          left: -65%; } }
      @media only screen and (max-width: 50em) and (max-height: 68.75em) {
        .BgImage_BgImage__SubImage_3__361Yz {
          width: 55rem;
          left: -65%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__SubImage_3__361Yz {
          width: 55rem;
          top: 25%;
          left: 30%;
          -webkit-transform: translateZ(0);
                  transform: translateZ(0);
          opacity: .13 !important; } }
      @media only screen and (max-width: 28.13em) and (max-height: 46.875em) {
        .BgImage_BgImage__SubImage_3__361Yz {
          width: 50rem;
          top: 35%;
          left: 40%; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .BgImage_BgImage__SubImage_3__361Yz {
          width: 45rem;
          left: 40%; } }
    .BgImage_BgImage__SubImage_4__2cjft {
      top: 0%;
      left: -90%;
      width: 52rem; }
      @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
        .BgImage_BgImage__SubImage_4__2cjft {
          width: 45rem;
          top: 10%;
          left: -75%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .BgImage_BgImage__SubImage_4__2cjft {
          width: 40rem;
          top: 30%;
          left: -65%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .BgImage_BgImage__SubImage_4__2cjft {
          width: 38rem;
          left: -55%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__SubImage_4__2cjft {
          display: none; } }
  .BgImage_BgImage__Shape__1mDzo {
    display: block;
    position: absolute;
    -webkit-transform: translateZ(-250px);
            transform: translateZ(-250px);
    z-index: -5;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    height: 73rem;
    object-fit: cover; }
    .BgImage_BgImage__Shape_1__3C1BP {
      top: -100%;
      left: -170%; }
      @media only screen and (max-width: 101.56em) {
        .BgImage_BgImage__Shape_1__3C1BP {
          height: 73rem;
          left: -150%; } }
      @media only screen and (max-width: 84.38em) {
        .BgImage_BgImage__Shape_1__3C1BP {
          height: 70rem;
          left: -120%;
          top: -80%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .BgImage_BgImage__Shape_1__3C1BP {
          height: 70rem;
          top: -85%; } }
      @media only screen and (max-width: 50em) and (max-height: 68.75em) {
        .BgImage_BgImage__Shape_1__3C1BP {
          height: 80rem;
          top: -110%;
          left: -125%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__Shape_1__3C1BP {
          height: 130rem;
          top: -80%;
          left: 20%;
          opacity: .1 !important; } }
      @media only screen and (max-width: 28.13em) and (max-height: 46.875em) {
        .BgImage_BgImage__Shape_1__3C1BP {
          height: 120rem;
          top: -50%;
          left: 50%; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .BgImage_BgImage__Shape_1__3C1BP {
          height: 110rem;
          top: -50%;
          left: 30%; } }
    .BgImage_BgImage__Shape_2__orwAZ {
      top: -100%;
      left: -160%; }
      @media only screen and (max-width: 101.56em) {
        .BgImage_BgImage__Shape_2__orwAZ {
          height: 70rem;
          left: -140%; } }
      @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
        .BgImage_BgImage__Shape_2__orwAZ {
          height: 65rem;
          top: -80%;
          left: -120%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .BgImage_BgImage__Shape_2__orwAZ {
          height: 65rem;
          top: -60%;
          left: -110%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .BgImage_BgImage__Shape_2__orwAZ {
          left: -105%; } }
      @media only screen and (max-width: 50em) and (max-height: 68.75em) {
        .BgImage_BgImage__Shape_2__orwAZ {
          top: -80%;
          left: -110%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__Shape_2__orwAZ {
          height: 120rem;
          top: -50%;
          left: 55%;
          opacity: .075 !important; } }
      @media only screen and (max-width: 28.13em) and (max-height: 46.875em) {
        .BgImage_BgImage__Shape_2__orwAZ {
          height: 110rem; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .BgImage_BgImage__Shape_2__orwAZ {
          height: 95rem; } }
    .BgImage_BgImage__Shape_3__CH5aX {
      top: -100%;
      left: -150%;
      height: 70rem; }
      @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
        .BgImage_BgImage__Shape_3__CH5aX {
          top: -80%;
          left: -120%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .BgImage_BgImage__Shape_3__CH5aX {
          top: -80%;
          left: -120%; } }
      @media only screen and (max-width: 50em) and (max-height: 68.75em) {
        .BgImage_BgImage__Shape_3__CH5aX {
          height: 73rem;
          top: -100%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__Shape_3__CH5aX {
          height: 130rem;
          top: -40%;
          left: 60%;
          opacity: .1 !important; } }
      @media only screen and (max-width: 28.13em) and (max-height: 46.875em) {
        .BgImage_BgImage__Shape_3__CH5aX {
          height: 120rem;
          top: -20%;
          left: 80%; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .BgImage_BgImage__Shape_3__CH5aX {
          height: 110rem;
          left: 70%; } }
    .BgImage_BgImage__Shape_4__3tED_ {
      top: -80%;
      left: -180%; }
      @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
        .BgImage_BgImage__Shape_4__3tED_ {
          top: -80%;
          left: -160%; } }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .BgImage_BgImage__Shape_4__3tED_ {
          height: 70rem;
          top: -60%;
          left: -140%; } }
      @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
        .BgImage_BgImage__Shape_4__3tED_ {
          height: 120rem;
          top: -50%;
          left: 0;
          opacity: .1 !important; } }
      @media only screen and (max-width: 28.13em) and (max-height: 46.875em) {
        .BgImage_BgImage__Shape_4__3tED_ {
          height: 110rem; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .BgImage_BgImage__Shape_4__3tED_ {
          height: 105rem; } }

.Tech_Tech__2ezpH {
  display: block;
  position: relative;
  width: 3.7rem;
  height: 3.7rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: all 200ms ease-out; }
  @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
    .Tech_Tech__2ezpH {
      width: 4rem;
      height: 4rem; } }
  @media only screen and (max-width: 80em) and (max-height: 34.68em) {
    .Tech_Tech__2ezpH {
      width: 3.75rem;
      height: 3.75rem; } }
  @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
    .Tech_Tech__2ezpH {
      width: 4rem;
      height: 4rem; } }
  @media only screen and (max-width: 68.75em) and (max-height: 50em) {
    .Tech_Tech__2ezpH {
      width: 4rem;
      height: 4rem; } }
  @media only screen and (max-width: 50em) and (max-height: 68.75em) {
    .Tech_Tech__2ezpH {
      width: 4rem;
      height: 4rem; } }
  @media only screen and (max-width: 46.88em) {
    .Tech_Tech__2ezpH {
      width: 4.75rem;
      height: 4.75rem; } }
  @media only screen and (max-width: 34.38em) {
    .Tech_Tech__2ezpH {
      width: 4rem;
      height: 4rem; } }
  @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
    .Tech_Tech__2ezpH {
      width: 4.5rem;
      height: 4.5rem; } }
  @media only screen and (max-width: 28.13em) and (max-height: 53.13em) {
    .Tech_Tech__2ezpH {
      width: 4.1rem;
      height: 4.1rem; } }
  @media only screen and (max-width: 28.13em) and (max-height: 46.88em) {
    .Tech_Tech__2ezpH {
      width: 4.5rem;
      height: 4.5rem; } }
  @media only screen and (max-width: 25em) and (max-height: 43.75em) {
    .Tech_Tech__2ezpH {
      width: 4rem;
      height: 4rem; } }
  @media only screen and (max-width: 23.44em) and (max-height: 46.88em) {
    .Tech_Tech__2ezpH {
      width: 4rem;
      height: 4rem; } }
  @media only screen and (max-width: 23.44em) and (max-height: 43.75em) {
    .Tech_Tech__2ezpH {
      width: 4.1rem;
      height: 4.1rem; } }
  @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
    .Tech_Tech__2ezpH {
      width: 3.5rem;
      height: 3.5rem; } }
  .Tech_Tech__2ezpH:hover::before {
    opacity: 1; }
  .Tech_Tech__2ezpH:hover .Tech_Tech__Image__1L1bO {
    opacity: 0.12; }
  .Tech_Tech__2ezpH::before {
    content: attr(data-text);
    font-size: 1.6rem;
    font-weight: bold;
    text-shadow: 0 0 2px #000;
    pointer-events: none;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    transition: all 200ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    padding: 1rem 1rem;
    text-align: center;
    opacity: 0;
    letter-spacing: 0.15rem;
    z-index: 100; }
  .Tech_Tech__Image__1L1bO {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: all 200ms ease-out;
    object-fit: contain;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }

.Technologies_Technologies__1xlIJ {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  justify-content: center;
  align-items: start;
  margin-bottom: 2rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
    .Technologies_Technologies__1xlIJ {
      grid-template-columns: repeat(3, 1fr); } }
  @media only screen and (max-width: 80em) and (max-height: 34.68em) {
    .Technologies_Technologies__1xlIJ {
      grid-template-columns: repeat(5, 1fr);
      margin-bottom: 0; } }
  @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
    .Technologies_Technologies__1xlIJ {
      grid-template-columns: repeat(3, 1fr); } }
  @media only screen and (max-width: 50em) and (max-height: 68.75em) {
    .Technologies_Technologies__1xlIJ {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 46.88em) and (max-height: 62.5em) {
    .Technologies_Technologies__1xlIJ {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 40.63em) and (max-height: 62.5em) {
    .Technologies_Technologies__1xlIJ {
      grid-template-columns: repeat(3, 1fr); } }
  @media only screen and (max-width: 31.25em) and (max-height: 62.5em) {
    .Technologies_Technologies__1xlIJ {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
    .Technologies_Technologies__1xlIJ {
      grid-template-columns: repeat(3, 1fr); } }
  @media only screen and (max-width: 23.44em) and (max-height: 43.75em) {
    .Technologies_Technologies__1xlIJ {
      grid-template-columns: repeat(3, 1fr); } }
  .Technologies_Technologies__Title__1mq3U {
    display: block;
    margin-top: 1rem;
    text-transform: uppercase;
    font-family: 'Blanch';
    font-weight: 400;
    font-size: 3rem;
    letter-spacing: 0.25rem;
    text-shadow: 0 0 1px #333;
    opacity: .7; }
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      .Technologies_Technologies__Title__1mq3U {
        margin-top: 1.5rem; } }
  .Technologies_Technologies__1xlIJ > * {
    margin-bottom: 1rem; }
    .Technologies_Technologies__1xlIJ > *:not(:nth-child(3n+3)) {
      margin-right: 1.3rem; }
    .Technologies_Technologies__1xlIJ > *:nth-child(3n+3) {
      margin-right: 0; }
    @media only screen and (max-width: 87.5em) and (max-height: 68.75em) {
      .Technologies_Technologies__1xlIJ > * {
        margin-bottom: 2.5rem; }
        .Technologies_Technologies__1xlIJ > *:not(:nth-child(3n+3)) {
          margin-right: 1.75rem; }
        .Technologies_Technologies__1xlIJ > *:nth-child(3n+3) {
          margin-right: 0; } }
    @media only screen and (max-width: 80em) and (max-height: 34.68em) {
      .Technologies_Technologies__1xlIJ > * {
        margin-bottom: 1.5rem; }
        .Technologies_Technologies__1xlIJ > *:not(:nth-child(5n+5)) {
          margin-right: 1rem; } }
    @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
      .Technologies_Technologies__1xlIJ > * {
        margin-bottom: 3rem; }
        .Technologies_Technologies__1xlIJ > *:not(:nth-child(3n+3)) {
          margin-right: 1.75rem; }
        .Technologies_Technologies__1xlIJ > *:nth-child(3n+3) {
          margin-right: 0; } }
    @media only screen and (max-width: 68.75em) and (max-height: 50em) {
      .Technologies_Technologies__1xlIJ > * {
        margin-bottom: 1.5rem; }
        .Technologies_Technologies__1xlIJ > *:not(:nth-child(3n+3)) {
          margin-right: 1.5rem; } }
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      .Technologies_Technologies__1xlIJ > * {
        margin-bottom: 1.25rem; }
        .Technologies_Technologies__1xlIJ > *:not(:nth-child(2n+2)) {
          margin-right: 2rem; } }
    @media only screen and (max-width: 46.88em) and (max-height: 62.5em) {
      .Technologies_Technologies__1xlIJ > *:not(:nth-child(2n+2)) {
        margin-right: 2rem; } }
    @media only screen and (max-width: 40.63em) and (max-height: 62.5em) {
      .Technologies_Technologies__1xlIJ > *:not(:nth-child(3n+3)) {
        margin-right: 2rem; } }
    @media only screen and (max-width: 31.25em) and (max-height: 62.5em) {
      .Technologies_Technologies__1xlIJ > *:not(:nth-child(2n+2)) {
        margin-right: 2rem; } }
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      .Technologies_Technologies__1xlIJ > * {
        margin-bottom: 1rem; }
        .Technologies_Technologies__1xlIJ > *:not(:nth-child(3n+3)) {
          margin-right: 1rem; }
        .Technologies_Technologies__1xlIJ > *:nth-child(3n+3) {
          margin-right: 0; } }
    @media only screen and (max-width: 28.13em) and (max-height: 46.88em) {
      .Technologies_Technologies__1xlIJ > * {
        margin-bottom: 1.3rem; }
        .Technologies_Technologies__1xlIJ > *:not(:nth-child(3n+3)) {
          margin-right: .95rem; }
        .Technologies_Technologies__1xlIJ > *:nth-child(3n+3) {
          margin-right: 0; } }
    @media only screen and (max-width: 23.44em) and (max-height: 43.75em) {
      .Technologies_Technologies__1xlIJ > * {
        margin-bottom: 1.45rem; }
        .Technologies_Technologies__1xlIJ > *:not(:nth-child(3n+3)) {
          margin-right: .5rem; }
        .Technologies_Technologies__1xlIJ > *:nth-child(3n+3) {
          margin-right: 0; } }
    @media only screen and (max-width: 23.44em) and (max-height: 40.25em) {
      .Technologies_Technologies__1xlIJ > *:not(:nth-child(3n+3)) {
        margin-right: .3rem; }
      .Technologies_Technologies__1xlIJ > *:nth-child(3n+3) {
        margin-right: 0; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Technologies_Technologies__1xlIJ > *:not(:nth-child(3n+3)) {
        margin-right: .35rem; }
      .Technologies_Technologies__1xlIJ > *:nth-child(3n+3) {
        margin-right: 0; } }

.ProjectButton_ProjectButton__1-nL4 {
  position: relative;
  overflow: hidden;
  padding: 0 3rem;
  border-radius: 5px;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  text-shadow: 0 0 1px #000;
  transition: border-color 175ms, background-color 175ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 1000;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  @media only screen and (max-width: 56.25em) {
    .ProjectButton_ProjectButton__1-nL4 {
      padding: 0 1.5rem;
      width: 100%; } }
  @media only screen and (max-width: 25em) {
    .ProjectButton_ProjectButton__1-nL4 {
      padding: 0; } }
  .ProjectButton_ProjectButton_blue__1NFaI {
    color: #CEECFD;
    border: 2px solid #CEECFD; }
    .ProjectButton_ProjectButton_blue__1NFaI::after {
      color: #02D6B2; }
    .ProjectButton_ProjectButton_blue__1NFaI:hover {
      border-color: #02D6B2;
      background-color: rgba(2, 214, 178, 0.1); }
  .ProjectButton_ProjectButton_yellow__3aBZC {
    color: #FFF099;
    border: 2px solid #FFF099; }
    .ProjectButton_ProjectButton_yellow__3aBZC::after {
      color: #F7FF1A; }
    .ProjectButton_ProjectButton_yellow__3aBZC:hover {
      border-color: #F7FF1A;
      background-color: rgba(247, 255, 26, 0.1); }
  .ProjectButton_ProjectButton_orange__2034z {
    color: #FACB9E;
    border: 2px solid #FACB9E; }
    .ProjectButton_ProjectButton_orange__2034z::after {
      color: #F08D0F; }
    .ProjectButton_ProjectButton_orange__2034z:hover {
      border-color: #F08D0F;
      background-color: rgba(240, 141, 15, 0.1); }
  .ProjectButton_ProjectButton_pink__TO94g {
    color: #ECD4F7;
    border: 2px solid #ECD4F7; }
    .ProjectButton_ProjectButton_pink__TO94g::after {
      color: #FA6BF9; }
    .ProjectButton_ProjectButton_pink__TO94g:hover {
      border-color: #FA6BF9;
      background-color: rgba(250, 107, 249, 0.1); }
  .ProjectButton_ProjectButton__1-nL4::after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    text-align: center;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    font-weight: bold; }
  .ProjectButton_ProjectButton__1-nL4 > span {
    display: block;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .ProjectButton_ProjectButton__1-nL4::after,
  .ProjectButton_ProjectButton__1-nL4 > span {
    padding: 1.5rem 3rem;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: opacity 175ms, -webkit-transform 175ms;
    transition: transform 175ms, opacity 175ms;
    transition: transform 175ms, opacity 175ms, -webkit-transform 175ms; }
    @media only screen and (max-width: 34.38em) {
      .ProjectButton_ProjectButton__1-nL4::after,
      .ProjectButton_ProjectButton__1-nL4 > span {
        padding: 1rem 2rem; } }
    @media only screen and (max-width: 25em) and (max-height: 37.5em) {
      .ProjectButton_ProjectButton__1-nL4::after,
      .ProjectButton_ProjectButton__1-nL4 > span {
        padding: .7rem 2rem; } }
  .ProjectButton_ProjectButton__1-nL4:hover::after {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .ProjectButton_ProjectButton__1-nL4:hover > span {
    opacity: 0;
    -webkit-transform: translate(0, 75%);
            transform: translate(0, 75%); }

.Project_Project__3bvlV {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  pointer-events: all; }
  @media only screen and (max-width: 31.25em) and (max-height: 56.25em) {
    .Project_Project__3bvlV {
      top: 2%; } }
  @media only screen and (max-width: 28.13em) and (max-height: 46.88em) {
    .Project_Project__3bvlV {
      top: 3%; } }
  @media only screen and (max-width: 25em) and (max-height: 37.5em) {
    .Project_Project__3bvlV {
      top: 1.5%; } }
  @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
    .Project_Project__3bvlV {
      top: 3%; } }
  .Project_Project__Images__9lqsS {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: absolute;
    top: 50%;
    left: 32%;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    z-index: -15;
    pointer-events: none; }
    @media only screen and (max-width: 80em) and (max-height: 34.68em) {
      .Project_Project__Images__9lqsS {
        max-width: 50%;
        left: 27%; } }
    @media only screen and (max-width: 46.88em) and (max-height: 62.5em) {
      .Project_Project__Images__9lqsS {
        left: 25%;
        top: 50%; } }
    @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
      .Project_Project__Images__9lqsS {
        left: 50%;
        top: 30%; } }
  .Project_Project__Text__2j-Hb {
    width: 100%;
    max-width: 60rem;
    position: absolute;
    top: 50%;
    left: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0); }
    @media only screen and (max-width: 90.63em) {
      .Project_Project__Text__2j-Hb {
        max-width: 55rem;
        left: 49%; } }
    @media only screen and (max-width: 80em) and (max-height: 34.68em) {
      .Project_Project__Text__2j-Hb {
        max-width: 50%;
        left: 42.5%; } }
    @media only screen and (max-width: 71.88em) {
      .Project_Project__Text__2j-Hb {
        max-width: 48rem;
        left: 49%; } }
    @media only screen and (max-width: 46.88em) and (max-height: 62.5em) {
      .Project_Project__Text__2j-Hb {
        left: 43%; } }
    @media only screen and (max-width: 40.63em) and (max-height: 59.38em) {
      .Project_Project__Text__2j-Hb {
        max-width: 90%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0); } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Project_Project__Text__2j-Hb {
        max-width: 90%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0); } }
  .Project_Project__Title__3rMVH {
    position: relative;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: -webkit-max-content;
    width: max-content;
    height: 100%;
    margin-bottom: 0;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Project_Project__Title__3rMVH {
        margin-left: 0; } }
  .Project_Project__YearRole__2kA5V, .Project_Project__TitleSeparator__3-F-- {
    opacity: .7; }
  .Project_Project__YearRole__2kA5V {
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    top: 50%;
    left: 100%;
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%; }
  .Project_Project__TitleSeparator__3-F-- {
    display: block;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    height: 9rem;
    width: 4px;
    border-radius: 100px;
    margin-right: 1.5rem;
    box-shadow: 0 0 2px #333; }
    @media only screen and (max-width: 90.63em) {
      .Project_Project__TitleSeparator__3-F-- {
        height: 9rem; } }
    @media only screen and (max-width: 28.13em) {
      .Project_Project__TitleSeparator__3-F-- {
        margin-right: 1rem; } }
    @media only screen and (max-width: 25em) and (max-height: 50em) {
      .Project_Project__TitleSeparator__3-F-- {
        height: 8rem; } }
    @media only screen and (max-width: 25em) and (max-height: 37.5em) {
      .Project_Project__TitleSeparator__3-F-- {
        height: 6rem; } }
    @media only screen and (max-width: 21.88em) {
      .Project_Project__TitleSeparator__3-F-- {
        height: 5rem;
        width: 3px; } }
  .Project_Project__Tech__8lfDi {
    display: flex;
    width: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    justify-content: flex-start;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }
    .Project_Project__Tech__8lfDi > *:not(:last-child) {
      margin-right: 3.5rem; }
      @media only screen and (max-width: 80em) and (max-height: 34.68em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 2rem; } }
      @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 2.5rem; } }
      @media only screen and (max-width: 68.75em) and (max-height: 50em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 2.6rem; } }
      @media only screen and (max-width: 50em) and (max-height: 68.75em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 4.75rem; } }
      @media only screen and (max-width: 46.88em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 4rem; } }
      @media only screen and (max-width: 37.5em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 3.5rem; } }
      @media only screen and (max-width: 34.38em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 2rem; } }
      @media only screen and (max-width: 28.13em) and (max-height: 53.13em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 2rem; } }
      @media only screen and (max-width: 28.13em) and (max-height: 46.88em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 1.8rem; } }
      @media only screen and (max-width: 25em) and (max-height: 53.13em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 1rem; } }
      @media only screen and (max-width: 23.44em) and (max-height: 43.75em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 2.25rem; } }
      @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
        .Project_Project__Tech__8lfDi > *:not(:last-child) {
          margin-right: 2rem; } }
  .Project_Project__Buttons__vyxuM {
    -webkit-transform: translateZ(0.1rem);
            transform: translateZ(0.1rem);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    display: flex;
    z-index: 500;
    width: 100%; }
    .Project_Project__Buttons__vyxuM > *:first-child {
      margin-right: 1rem; }

.Handle_Handle__s5ZnS {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  cursor: pointer; }
  .Handle_Handle_pageIndicator__15Fp2 {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    z-index: 100;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .Handle_Handle__LeftArrow__1mgMG, .Handle_Handle__RightArrow__3K0ZN {
    display: block;
    position: absolute;
    top: calc(50% + 1px);
    height: 1rem;
    width: 1rem;
    opacity: 0;
    transition: all 300ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-filter: invert(1);
            filter: invert(1); }
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      .Handle_Handle__LeftArrow__1mgMG, .Handle_Handle__RightArrow__3K0ZN {
        display: none; } }
    .Handle_Handle__LeftArrow_arrow__fHY-n, .Handle_Handle__RightArrow_arrow__3xYzr {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 5rem;
      height: 5rem;
      padding: 2rem; }
  .Handle_Handle__LeftArrow__1mgMG {
    left: 5%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .Handle_Handle__RightArrow__3K0ZN {
    right: 5%;
    -webkit-transform: translate(0, -50%) rotateZ(180deg);
            transform: translate(0, -50%) rotateZ(180deg); }
  .Handle_Handle__Current__2PCwQ, .Handle_Handle__Slash__347rt, .Handle_Handle__Total__1Qd6k {
    display: block;
    position: absolute;
    transition: all 300ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    font-weight: bold; }
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      .Handle_Handle__Current__2PCwQ, .Handle_Handle__Slash__347rt, .Handle_Handle__Total__1Qd6k {
        display: none; } }
  .Handle_Handle__Current__2PCwQ {
    font-size: 4rem;
    bottom: 5%;
    left: -.75rem; }
  .Handle_Handle__Slash__347rt {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 2px;
    background-color: #000;
    height: 4.5rem; }
  .Handle_Handle__Total__1Qd6k {
    font-size: 2rem;
    bottom: 5%;
    right: -.25rem; }
  .Handle_Handle__Mobile__3VvKD {
    display: none; }
  @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
    .Handle_Handle__Mobile__3VvKD {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 2%;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
      width: 31%;
      height: 4rem;
      z-index: 100; }
    .Handle_Handle__MobilePageNum__1ZN9V {
      font-size: 3rem;
      font-weight: bold; }
    .Handle_Handle__MobileLeftArrow__3UIkW, .Handle_Handle__MobileRightArrow__LIH0q {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6.75rem;
      height: 6.75rem;
      padding: 2.25rem;
      -webkit-filter: invert(1);
              filter: invert(1);
      top: 50%;
      bottom: 0; }
      .Handle_Handle__MobileLeftArrow_arrow__3eell, .Handle_Handle__MobileRightArrow_arrow__16FQG {
        width: 100%;
        height: 100%; }
    .Handle_Handle__MobileLeftArrow__3UIkW {
      left: 0;
      -webkit-transform: translateY(-53%);
              transform: translateY(-53%); }
    .Handle_Handle__MobileRightArrow__LIH0q {
      right: 0;
      -webkit-transform: rotateZ(180deg) translateY(53%);
              transform: rotateZ(180deg) translateY(53%); } }

.PageIndicatorBar_PageIndicatorBar__20yYo {
  display: block;
  text-align: center;
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .PageIndicatorBar_PageIndicatorBar_left__3q-L1, .PageIndicatorBar_PageIndicatorBar_right__3LYSJ {
    display: block;
    position: absolute;
    height: 4px;
    background-color: #fff;
    border-radius: 100px;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .PageIndicatorBar_PageIndicatorBar_left__3q-L1 {
    left: 0;
    right: 50%; }
  .PageIndicatorBar_PageIndicatorBar_right__3LYSJ {
    right: 0;
    left: 50%; }

.PageIndicator_PageIndicator__2iNYD {
  position: absolute;
  left: 50%;
  bottom: 3rem;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  height: 5rem;
  z-index: 1000; }
  .PageIndicator_PageIndicator__PageNumber__3M_uU {
    display: none; }
  .PageIndicator_PageIndicator__2iNYD::before {
    content: 'Scroll to see more...';
    display: block;
    position: absolute;
    top: 102%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    font-size: 1.2rem;
    letter-spacing: 0.25rem; }
  @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
    .PageIndicator_PageIndicator__2iNYD {
      display: none; }
      .PageIndicator_PageIndicator__PageNumber__3M_uU {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 2%;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
        font-size: 3rem;
        font-weight: bold;
        width: 4rem; }
        .PageIndicator_PageIndicator__PageNumber_left__1P7NK, .PageIndicator_PageIndicator__PageNumber_right__2XG_j {
          position: absolute;
          top: 50%;
          display: block;
          width: 4rem;
          height: 4rem;
          padding: 1.1rem;
          -webkit-filter: invert(1);
                  filter: invert(1); }
        .PageIndicator_PageIndicator__PageNumber_left__1P7NK {
          right: 100%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
        .PageIndicator_PageIndicator__PageNumber_right__2XG_j {
          left: 100%;
          -webkit-transform: rotateZ(180deg) translateY(50%);
                  transform: rotateZ(180deg) translateY(50%); } }
  .PageIndicator_PageIndicator__HandleProxy__2cXrb {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    z-index: 100000; }
  .PageIndicator_PageIndicator__2iNYD:hover .PageIndicator_PageIndicator__Handle__3UWYM > *:first-child,
  .PageIndicator_PageIndicator__2iNYD:active .PageIndicator_PageIndicator__Handle__3UWYM > *:first-child {
    left: 0;
    bottom: 50%;
    -webkit-transform: translate(0, 50%);
            transform: translate(0, 50%);
    font-size: 2rem;
    font-weight: bold; }
  .PageIndicator_PageIndicator__2iNYD:hover .PageIndicator_PageIndicator__Handle__3UWYM > *:nth-of-type(3),
  .PageIndicator_PageIndicator__2iNYD:active .PageIndicator_PageIndicator__Handle__3UWYM > *:nth-of-type(3) {
    -webkit-transform: rotateZ(90deg) translate(-50%, -50%);
            transform: rotateZ(90deg) translate(-50%, -50%);
    -webkit-transform-origin: top;
            transform-origin: top;
    top: calc(50% + 2.5px);
    height: 1.5rem; }
  .PageIndicator_PageIndicator__2iNYD:hover .PageIndicator_PageIndicator__Handle__3UWYM > *:nth-of-type(2),
  .PageIndicator_PageIndicator__2iNYD:hover .PageIndicator_PageIndicator__Handle__3UWYM > *:nth-of-type(5),
  .PageIndicator_PageIndicator__2iNYD:active .PageIndicator_PageIndicator__Handle__3UWYM > *:nth-of-type(2),
  .PageIndicator_PageIndicator__2iNYD:active .PageIndicator_PageIndicator__Handle__3UWYM > *:nth-of-type(5) {
    opacity: 1; }
  .PageIndicator_PageIndicator__2iNYD:hover .PageIndicator_PageIndicator__Handle__3UWYM > *:nth-of-type(2),
  .PageIndicator_PageIndicator__2iNYD:active .PageIndicator_PageIndicator__Handle__3UWYM > *:nth-of-type(2) {
    left: -1.5rem; }
  .PageIndicator_PageIndicator__2iNYD:hover .PageIndicator_PageIndicator__Handle__3UWYM > *:nth-of-type(5),
  .PageIndicator_PageIndicator__2iNYD:active .PageIndicator_PageIndicator__Handle__3UWYM > *:nth-of-type(5) {
    right: -1.5rem; }
  .PageIndicator_PageIndicator__2iNYD:hover .PageIndicator_PageIndicator__Handle__3UWYM > *:last-child,
  .PageIndicator_PageIndicator__2iNYD:active .PageIndicator_PageIndicator__Handle__3UWYM > *:last-child {
    right: -.25rem;
    bottom: 50%;
    -webkit-transform: translate(0, 50%);
            transform: translate(0, 50%); }

.Portfolio_Portfolio__18rSb {
  position: relative;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-perspective: 300rem;
          perspective: 300rem;
  -webkit-perspective-origin: 35% 60%;
          perspective-origin: 35% 60%;
  pointer-events: none; }

.WordEmphasis_WordEmphasis__1cYEZ {
  position: relative;
  width: 100%;
  height: 100%;
  color: #00FEFF;
  transition: all 300ms ease-out;
  text-transform: capitalize;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .WordEmphasis_WordEmphasis__1cYEZ::before, .WordEmphasis_WordEmphasis__1cYEZ::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    border-radius: 100px;
    background-color: #056CF2;
    transition: right 300ms ease-out, left 300ms ease-out;
    opacity: .6;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .WordEmphasis_WordEmphasis__1cYEZ::before {
    top: 110%;
    left: 0;
    right: 100%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .WordEmphasis_WordEmphasis__1cYEZ::after {
    left: 100%;
    right: 0%;
    bottom: 110%;
    -webkit-transform: translateY(50%);
            transform: translateY(50%); }
  .WordEmphasis_WordEmphasis__1cYEZ:hover::before {
    right: 0; }
  .WordEmphasis_WordEmphasis__1cYEZ:hover::after {
    left: 0; }

.Bio_Bio__2EXPA {
  display: block;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: relative; }
  @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
    .Bio_Bio__2EXPA > *:nth-of-type(4) {
      display: none; } }
  .Bio_Bio__Paragraph__3C-j9, .Bio_Bio__Closing__1CkGw {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    color: #ccc;
    font-size: 1.7rem;
    letter-spacing: 0.15rem;
    line-height: 1.8;
    font-weight: bold;
    width: 100%; }
    @media only screen and (max-width: 50em) and (max-height: 65.63em) {
      .Bio_Bio__Paragraph__3C-j9, .Bio_Bio__Closing__1CkGw {
        font-size: 2rem; } }
    @media only screen and (max-width: 28.125em) and (max-height: 53.13em) {
      .Bio_Bio__Paragraph__3C-j9, .Bio_Bio__Closing__1CkGw {
        font-size: 1.9rem; } }
    @media only screen and (max-width: 28.125em) and (max-height: 50em) {
      .Bio_Bio__Paragraph__3C-j9, .Bio_Bio__Closing__1CkGw {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 25em) and (max-height: 53.13em) {
      .Bio_Bio__Paragraph__3C-j9, .Bio_Bio__Closing__1CkGw {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 25em) and (max-height: 43.75em) {
      .Bio_Bio__Paragraph__3C-j9, .Bio_Bio__Closing__1CkGw {
        font-size: 1.7rem; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Bio_Bio__Paragraph__3C-j9, .Bio_Bio__Closing__1CkGw {
        font-size: 1.6rem; } }
  .Bio_Bio__Paragraph__3C-j9 {
    font-family: 'Alcubierre';
    top: 1rem;
    margin-bottom: 1rem; }
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      .Bio_Bio__Paragraph__3C-j9 {
        margin-bottom: .5rem; } }
    @media only screen and (max-width: 21.88em) and (max-height: 43.75em) {
      .Bio_Bio__Paragraph__3C-j9 {
        margin-bottom: .25rem; } }

.Skill_Skill__1L0HN {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2.2rem;
  width: 8rem;
  height: 8rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  margin: 0rem -.4rem;
  transition: all 200ms ease-out; }
  @media only screen and (max-width: 50em) and (max-height: 65.63em) {
    .Skill_Skill__1L0HN {
      padding: 1.7rem;
      margin: .75rem -.3rem; } }
  @media only screen and (max-width: 28.13em) and (max-height: 53.13em) {
    .Skill_Skill__1L0HN {
      width: 8rem;
      height: 8rem;
      margin: 0 -.5rem 3rem;
      padding: 1.7rem; } }
  @media only screen and (max-width: 28.13em) and (max-height: 46.88em) {
    .Skill_Skill__1L0HN {
      width: 7.5rem;
      height: 7.5rem;
      margin: 0 -.5rem 3rem; } }
  @media only screen and (max-width: 25em) and (max-height: 53.13em) {
    .Skill_Skill__1L0HN {
      width: 8rem;
      height: 8rem;
      padding: 1.9rem;
      margin: .25rem -.3rem; } }
  @media only screen and (max-width: 25em) and (max-height: 43.75em) {
    .Skill_Skill__1L0HN {
      width: 7rem;
      height: 7rem;
      padding: 1.3rem;
      margin: .5rem -.3rem; } }
  @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
    .Skill_Skill__1L0HN {
      width: 5.5rem;
      height: 5.5rem;
      padding: 1.2rem;
      margin: .4rem .3rem; } }
  .Skill_Skill__1L0HN:hover {
    box-shadow: 0 0 3rem rgba(255, 2, 54, 0.3); }
  .Skill_Skill__1L0HN:hover .Skill_Skill__NameWrapper__7pFIA {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 1; }
    @media only screen and (max-width: 65.63em) and (max-height: 87.5em) {
      .Skill_Skill__1L0HN:hover .Skill_Skill__NameWrapper__7pFIA {
        -webkit-transform: translate(-50%, -70%);
                transform: translate(-50%, -70%); } }
    @media only screen and (max-width: 50em) and (max-height: 65.63em) {
      .Skill_Skill__1L0HN:hover .Skill_Skill__NameWrapper__7pFIA {
        -webkit-transform: translate(-50%, -10%);
                transform: translate(-50%, -10%); } }
    @media only screen and (max-width: 28.13em) and (max-height: 53.13em) {
      .Skill_Skill__1L0HN:hover .Skill_Skill__NameWrapper__7pFIA {
        -webkit-transform: translate(-50%, -20%);
                transform: translate(-50%, -20%); } }
    @media only screen and (max-width: 28.13em) and (max-height: 46.88em) {
      .Skill_Skill__1L0HN:hover .Skill_Skill__NameWrapper__7pFIA {
        -webkit-transform: translate(-50%, -15%);
                transform: translate(-50%, -15%); } }
    @media only screen and (max-width: 25em) and (max-height: 53.13em) {
      .Skill_Skill__1L0HN:hover .Skill_Skill__NameWrapper__7pFIA {
        -webkit-transform: translate(-50%, -40%);
                transform: translate(-50%, -40%); } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Skill_Skill__1L0HN:hover .Skill_Skill__NameWrapper__7pFIA {
        -webkit-transform: translate(-50%, -20%);
                transform: translate(-50%, -20%); } }
  .Skill_Skill__1L0HN:hover .Skill_Skill__ImageWrapper__2NxMR::before {
    border: 2px solid #FF0236; }
  .Skill_Skill__ImageWrapper__2NxMR::before, .Skill_Skill__NameWrapper__7pFIA::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 5.6rem;
    height: 5.6rem;
    z-index: -1;
    border-radius: 50%;
    transition: all 300ms ease-out; }
    @media only screen and (max-width: 50em) and (max-height: 65.63em) {
      .Skill_Skill__ImageWrapper__2NxMR::before, .Skill_Skill__NameWrapper__7pFIA::before {
        width: 6.75rem;
        height: 6.75rem; } }
    @media only screen and (max-width: 28.13em) and (max-height: 53.13em) {
      .Skill_Skill__ImageWrapper__2NxMR::before, .Skill_Skill__NameWrapper__7pFIA::before {
        width: 6.375rem;
        height: 6.375rem; } }
    @media only screen and (max-width: 28.13em) and (max-height: 46.88em) {
      .Skill_Skill__ImageWrapper__2NxMR::before, .Skill_Skill__NameWrapper__7pFIA::before {
        width: 6rem;
        height: 6rem; } }
    @media only screen and (max-width: 25em) and (max-height: 53.13em) {
      .Skill_Skill__ImageWrapper__2NxMR::before, .Skill_Skill__NameWrapper__7pFIA::before {
        width: 6rem;
        height: 6rem; } }
    @media only screen and (max-width: 25em) and (max-height: 43.75em) {
      .Skill_Skill__ImageWrapper__2NxMR::before, .Skill_Skill__NameWrapper__7pFIA::before {
        width: 5.6rem;
        height: 5.6rem; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Skill_Skill__ImageWrapper__2NxMR::before, .Skill_Skill__NameWrapper__7pFIA::before {
        width: 4.9rem;
        height: 4.9rem; } }
  .Skill_Skill__ImageWrapper__2NxMR {
    width: 90%;
    height: 90%;
    margin: 0 auto;
    position: relative;
    transition: all 300ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .Skill_Skill__ImageWrapper__2NxMR::before {
    border: 2px solid #0597F2; }
  .Skill_Skill__Image__2uKBo {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .Skill_Skill__NameWrapper__7pFIA {
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 50%;
    opacity: 0;
    transition: all 300ms ease-out;
    -webkit-transform: translate(-50%, -4rem);
            transform: translate(-50%, -4rem);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .Skill_Skill__Name__1vhnf {
    display: block;
    color: #ccc;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.15rem;
    text-align: center;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    white-space: nowrap; }
    @media only screen and (max-width: 28.13em) and (max-height: 53.13em) {
      .Skill_Skill__Name__1vhnf {
        font-size: 1.6rem;
        white-space: normal; } }
    @media only screen and (max-width: 28.13em) and (max-height: 46.88em) {
      .Skill_Skill__Name__1vhnf {
        white-space: normal; } }
    @media only screen and (max-width: 25em) and (max-height: 53.13em) {
      .Skill_Skill__Name__1vhnf {
        font-size: 1.6rem;
        white-space: nowrap; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Skill_Skill__Name__1vhnf {
        font-size: 1.5rem;
        letter-spacing: 0.1rem;
        white-space: normal; } }

.Skills_Skills__Jx-bW {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 300ms ease-out;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }
  @media only screen and (max-width: 28.125em) and (max-height: 53.13em), only screen and (max-width: 40.63em) and (max-height: 59.38em), only screen and (max-width: 80em) and (max-height: 34.68em) {
    .Skills_Skills__Jx-bW {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate3d(-50%, -50%, 0);
              transform: translate3d(-50%, -50%, 0);
      opacity: 0;
      padding: 15% 5% 0;
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.9);
      visibility: hidden; } }
  @media only screen and (max-width: 80em) and (max-height: 34.68em) {
    .Skills_Skills__Jx-bW {
      padding-top: 0;
      padding-bottom: 5%; } }
  @media only screen and (max-width: 28.125em) and (max-height: 46.88em) {
    .Skills_Skills__Jx-bW {
      padding: 15% 5% 0;
      -webkit-transform: translate3d(-50%, -48%, 0);
              transform: translate3d(-50%, -48%, 0); } }
  @media only screen and (max-width: 25em) and (max-height: 53.13em) {
    .Skills_Skills__Jx-bW {
      padding: 12% 7% 0; } }
  @media only screen and (max-width: 25em) and (max-height: 43.75em) {
    .Skills_Skills__Jx-bW {
      -webkit-transform: translate3d(-50%, -50%, 0);
              transform: translate3d(-50%, -50%, 0);
      padding: 18% 5% 0; } }
  @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
    .Skills_Skills__Jx-bW {
      padding: 20% 2% 0; } }
  @media only screen and (max-width: 28.125em) and (max-height: 53.13em), only screen and (max-width: 40.63em) and (max-height: 59.38em), only screen and (max-width: 80em) and (max-height: 34.68em) {
    .Skills_Skills_open__WMDCh {
      opacity: 1;
      visibility: visible; } }
  .Skills_Skills__Jx-bW > *:not(:first-child) {
    margin: 1rem 0 0; }
    @media only screen and (max-width: 28.13em) and (max-height: 53.13em) {
      .Skills_Skills__Jx-bW > *:not(:first-child) {
        margin: 1.5rem 0 0; } }
    @media only screen and (max-width: 28.13em) and (max-height: 46.88em) {
      .Skills_Skills__Jx-bW > *:not(:first-child) {
        margin: 2rem 0 0; } }
  .Skills_Skills__Toggle__pJ0Hv {
    display: none; }
    @media only screen and (max-width: 28.125em) and (max-height: 53.13em), only screen and (max-width: 28.125em) and (max-height: 46.88em), only screen and (max-width: 25em) and (max-height: 53.13em), only screen and (max-width: 40.63em) and (max-height: 59.38em), only screen and (max-width: 80em) and (max-height: 34.68em) {
      .Skills_Skills__Toggle__pJ0Hv {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 3rem;
        left: 3rem;
        width: 6rem;
        height: 6rem;
        z-index: 1000;
        border: 2px solid #0597F2;
        border-radius: 50%;
        cursor: pointer; }
        .Skills_Skills__Toggle__pJ0Hv > * {
          width: 100%;
          height: 100%;
          margin-top: .5rem;
          fill: #00FEFF; } }
    @media only screen and (max-width: 80em) and (max-height: 34.68em) {
      .Skills_Skills__Toggle__pJ0Hv {
        left: -10%; } }
    @media only screen and (max-width: 25em) and (max-height: 43.75em) {
      .Skills_Skills__Toggle__pJ0Hv {
        width: 5rem;
        height: 5rem;
        top: 2.25rem; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .Skills_Skills__Toggle__pJ0Hv {
        width: 4.5rem;
        height: 4.5rem;
        left: 2rem; } }
    .Skills_Skills__Toggle_open__ycghd {
      border: 2px solid #F08D0F; }
      .Skills_Skills__Toggle_open__ycghd > * {
        fill: #FF0000; }
  .Skills_Skills__Title__19wr0 {
    display: block;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.5rem;
    margin-bottom: 1.2rem;
    white-space: nowrap; }
    @media only screen and (max-width: 65.63em) and (max-height: 50em) {
      .Skills_Skills__Title__19wr0 {
        margin-bottom: 0; } }
    @media only screen and (max-width: 43.75em) and (max-height: 25em) {
      .Skills_Skills__Title__19wr0 {
        margin-bottom: -1rem;
        font-size: 1.8rem; } }
    @media only screen and (max-width: 28.125em) and (max-height: 46.88em) {
      .Skills_Skills__Title__19wr0 {
        margin-bottom: 0; } }
    @media only screen and (max-width: 25em) and (max-height: 53.13em) {
      .Skills_Skills__Title__19wr0 {
        margin-left: 1rem;
        margin-bottom: 0rem; } }
  .Skills_Skills__Lang__3B4J3, .Skills_Skills__Lib__fPkfP, .Skills_Skills__Tech__2Z1Bd {
    width: 100%;
    display: flex;
    flex-direction: column; }
  .Skills_Skills__Skills__3t6fn {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap; }

.ContactLink_ContactLink__2GkAr {
  position: relative;
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  text-decoration: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
    .ContactLink_ContactLink__2GkAr {
      width: 3rem;
      height: 3rem; } }
  .ContactLink_ContactLink__2GkAr:hover .ContactLink_ContactLink__Image__2FyJG {
    -webkit-filter: drop-shadow(0 0 1rem rgba(0, 254, 255, 0.8));
            filter: drop-shadow(0 0 1rem rgba(0, 254, 255, 0.8)); }
  .ContactLink_ContactLink__Image__2FyJG {
    display: block;
    width: 100%;
    height: 100%;
    transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .ContactLink_ContactLink__Name__2f79Z {
    font-size: 1.6rem;
    text-align: center;
    text-transform: capitalize;
    color: #ccc;
    margin-top: 1.25rem;
    opacity: 0;
    position: absolute;
    top: 110%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    @media only screen and (max-width: 87.5em) and (max-height: 68.75em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 87.5em) and (max-height: 68.75em) and (min-resolution: 150dpi), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (min-resolution: 150dpi), only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      .ContactLink_ContactLink__Name__2f79Z {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .ContactLink_ContactLink__Name__2f79Z {
        display: none; } }
  .ContactLink_ContactLink__2GkAr:hover .ContactLink_ContactLink__Name__2f79Z {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.ContactLinks_ContactLinks__1MkBX {
  display: flex;
  margin-top: 2rem; }
  @media only screen and (max-width: 87.5em) and (max-height: 68.75em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 87.5em) and (max-height: 68.75em) and (min-resolution: 150dpi), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (min-resolution: 150dpi), only screen and (max-width: 25em) and (max-height: 56.25em) {
    .ContactLinks_ContactLinks__1MkBX {
      margin-top: 1rem; } }
  @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
    .ContactLinks_ContactLinks__1MkBX {
      margin-top: .75rem; } }
  .ContactLinks_ContactLinks__1MkBX > *:not(:last-child) {
    margin-right: 4rem; }

.About_About__2fQ2C {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  position: relative; }
  @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
    .About_About__2fQ2C {
      width: 75%; } }
  @media only screen and (max-width: 68.75em) and (max-height: 50em) {
    .About_About__2fQ2C {
      width: 100%;
      padding: 0 10%;
      background-color: rgba(0, 0, 0, 0.8); } }
  @media only screen and (max-width: 50em) and (max-height: 65.63em) {
    .About_About__2fQ2C {
      width: 100%;
      padding: 0 10%;
      background-color: rgba(0, 0, 0, 0.8); } }
  @media only screen and (max-width: 46.88em) and (max-height: 59.38em) {
    .About_About__2fQ2C {
      width: 100%;
      padding: 0 5%; } }
  @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
    .About_About__2fQ2C {
      width: 100%;
      padding: 5% 5% 0;
      background-color: rgba(0, 0, 0, 0.8); } }
  @media only screen and (max-width: 28.13em) and (max-height: 50em) {
    .About_About__2fQ2C {
      padding-top: 5%; } }
  @media only screen and (max-width: 25em) and (max-height: 50em) {
    .About_About__2fQ2C {
      padding-top: 4%; } }
  @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
    .About_About__2fQ2C {
      padding: 7% 4% 0; } }
  .About_About__Text__1-wDE {
    width: 80%;
    padding-right: 7.5rem;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }
    @media only screen and (max-width: 80em) and (max-height: 34.68em) {
      .About_About__Text__1-wDE {
        width: 100%;
        padding-right: 0; } }
    @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
      .About_About__Text__1-wDE {
        width: 60%;
        padding-right: 5rem;
        margin-right: 4rem;
        margin-left: 2rem; } }
    @media only screen and (max-width: 68.75em) and (max-height: 50em) {
      .About_About__Text__1-wDE {
        margin-right: 0;
        padding-right: 0;
        padding: 0 2rem; } }
    @media only screen and (max-width: 50em) and (max-height: 65.63em) {
      .About_About__Text__1-wDE {
        padding: 0;
        margin: 0 7rem 0 5rem;
        width: 50%; } }
    @media only screen and (max-width: 46.88em) and (max-height: 59.38em) {
      .About_About__Text__1-wDE {
        margin-left: 0rem; } }
    @media only screen and (max-width: 75em) and (max-height: 56.25em), only screen and (max-width: 40.63em) and (max-height: 59.38em) {
      .About_About__Text__1-wDE {
        width: 100%;
        margin: 0; } }
  .About_About__Title__3JjiQ {
    width: 100%;
    height: 100%; }
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      .About_About__Title__3JjiQ {
        margin-bottom: -1rem; } }

.About_About__2ElYT {
  display: block;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 60rem;
          perspective: 60rem; }

