@import '../../../../Styles/variables';

.WordEmphasis {
  position: relative;
  width: 100%;
  height: 100%;
  color: $blue1;
  transition: all 300ms ease-out;
  text-transform: capitalize;
  backface-visibility: hidden;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    border-radius: 100px;
    background-color: $blue4;
    transition: right 300ms ease-out, left 300ms ease-out;
    opacity: .6;
    backface-visibility: hidden;
  }
  
  &::before {
    top: 110%;
    left: 0;
    right: 100%;
    transform: translateY(-50%);  
  }
  
  &::after {
    left: 100%;
    right: 0%;
    bottom: 110%;
    transform: translateY(50%);
  }

  &:hover::before {
    right: 0;
  }
  
  &:hover::after {
    left: 0;
  }
}