.LandingBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  z-index: 1000; }
  @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
    .LandingBtn {
      bottom: -3rem; } }
  @media only screen and (max-width: 25em) and (max-height: 43.75em) {
    .LandingBtn {
      bottom: -5rem; } }
  .LandingBtn__Link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-left: 0.25rem;
    color: inherit; }
  .LandingBtn:hover .LandingBtn__Text {
    opacity: 1;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    @media only screen and (max-width: 87.5em) and (max-height: 68.75em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 87.5em) and (max-height: 68.75em) and (min-resolution: 150dpi), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (min-resolution: 150dpi) {
      .LandingBtn:hover .LandingBtn__Text {
        -webkit-transform: translate(0, -60%);
                transform: translate(0, -60%); } }
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      .LandingBtn:hover .LandingBtn__Text {
        -webkit-transform: translate(0, -70%);
                transform: translate(0, -70%); } }
  .LandingBtn__Text {
    display: block;
    position: relative;
    margin-bottom: 1.5rem;
    opacity: 0;
    transition: all 400ms ease-in 50ms;
    width: -webkit-max-content;
    width: max-content;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    color: #fff; }
    @media only screen and (max-width: 87.5em) and (max-height: 68.75em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 87.5em) and (max-height: 68.75em) and (min-resolution: 150dpi), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (-webkit-min-device-pixel-ratio: 1.5625), only screen and (max-width: 68.75em) and (max-height: 87.5em) and (min-resolution: 150dpi) {
      .LandingBtn__Text {
        opacity: 1;
        -webkit-transform: translate(0, -60%);
                transform: translate(0, -60%);
        font-size: 1.6rem; } }
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      .LandingBtn__Text {
        -webkit-transform: translate(0, -70%);
                transform: translate(0, -70%);
        margin-bottom: 2rem; } }
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      .LandingBtn__Text {
        font-size: 1.5rem; } }
  .LandingBtn__Arrow {
    position: relative;
    width: 100px;
    height: 100px; }
    .LandingBtn__Arrow span {
      display: block;
      width: 28px;
      height: 28px;
      border-bottom: 4px solid #ff00ff;
      border-right: 4px solid #ff00ff;
      border-radius: 4px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      margin: -10px auto;
      -webkit-animation: animate 2.75s ease-in-out infinite, neonColors 20000ms ease-in-out infinite;
              animation: animate 2.75s ease-in-out infinite, neonColors 20000ms ease-in-out infinite; }
      @media only screen and (max-width: 68.75em) and (max-height: 50em), only screen and (max-width: 50em) and (max-height: 68.75em) {
        .LandingBtn__Arrow span {
          width: 22px;
          height: 22px; } }
      @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
        .LandingBtn__Arrow span {
          width: 21px;
          height: 21px;
          -webkit-animation: animateSmaller 2.75s ease-in-out infinite, neonColors2px 20000ms ease-in-out infinite;
                  animation: animateSmaller 2.75s ease-in-out infinite, neonColors2px 20000ms ease-in-out infinite;
          margin: -11px auto; } }
      @media only screen and (max-width: 25em) and (max-height: 43.75em) {
        .LandingBtn__Arrow span {
          width: 20px;
          height: 20px;
          -webkit-animation: animateSmallest 2.75s ease-in-out infinite, neonColors2px 20000ms ease-in-out infinite;
                  animation: animateSmallest 2.75s ease-in-out infinite, neonColors2px 20000ms ease-in-out infinite; } }
    .LandingBtn__Arrow span:nth-child(2) {
      -webkit-animation-delay: -0.25s;
              animation-delay: -0.25s; }
    .LandingBtn__Arrow span:nth-child(3) {
      -webkit-animation-delay: -0.45s;
              animation-delay: -0.45s; }

@-webkit-keyframes animate {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-15px, -15px);
            transform: rotate(45deg) translate(-15px, -15px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(15px, 15px);
            transform: rotate(45deg) translate(15px, 15px); } }

@keyframes animate {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-15px, -15px);
            transform: rotate(45deg) translate(-15px, -15px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(15px, 15px);
            transform: rotate(45deg) translate(15px, 15px); } }

@-webkit-keyframes animateSmaller {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-11px, -11px);
            transform: rotate(45deg) translate(-11px, -11px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(11px, 11px);
            transform: rotate(45deg) translate(11px, 11px); } }

@keyframes animateSmaller {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-11px, -11px);
            transform: rotate(45deg) translate(-11px, -11px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(11px, 11px);
            transform: rotate(45deg) translate(11px, 11px); } }

@-webkit-keyframes animateSmallest {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-7.5px, -7.5px);
            transform: rotate(45deg) translate(-7.5px, -7.5px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(7.5px, 7.5px);
            transform: rotate(45deg) translate(7.5px, 7.5px); } }

@keyframes animateSmallest {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-7.5px, -7.5px);
            transform: rotate(45deg) translate(-7.5px, -7.5px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(7.5px, 7.5px);
            transform: rotate(45deg) translate(7.5px, 7.5px); } }

@-webkit-keyframes neonColors {
  0%,
  12.5% {
    border-bottom: 4px solid #ff00ff;
    border-right: 4px solid #ff00ff; }
  25%,
  37.5% {
    border-bottom: 4px solid #39ff14;
    border-right: 4px solid #39ff14; }
  50%,
  62.5% {
    border-bottom: 4px solid #F7FF1A;
    border-right: 4px solid #F7FF1A; }
  75%,
  87.5% {
    border-bottom: 4px solid #00FEFF;
    border-right: 4px solid #00FEFF; }
  100% {
    border-bottom: 4px solid #ff00ff;
    border-right: 4px solid #ff00ff; } }

@keyframes neonColors {
  0%,
  12.5% {
    border-bottom: 4px solid #ff00ff;
    border-right: 4px solid #ff00ff; }
  25%,
  37.5% {
    border-bottom: 4px solid #39ff14;
    border-right: 4px solid #39ff14; }
  50%,
  62.5% {
    border-bottom: 4px solid #F7FF1A;
    border-right: 4px solid #F7FF1A; }
  75%,
  87.5% {
    border-bottom: 4px solid #00FEFF;
    border-right: 4px solid #00FEFF; }
  100% {
    border-bottom: 4px solid #ff00ff;
    border-right: 4px solid #ff00ff; } }

@-webkit-keyframes neonColors2px {
  0% {
    border-bottom: 2px solid #ff00ff;
    border-right: 2px solid #ff00ff; }
  25% {
    border-bottom: 2px solid #39ff14;
    border-right: 2px solid #39ff14; }
  50% {
    border-bottom: 2px solid #F7FF1A;
    border-right: 2px solid #F7FF1A; }
  75% {
    border-bottom: 2px solid #00FEFF;
    border-right: 2px solid #00FEFF; }
  100% {
    border-bottom: 2px solid #ff00ff;
    border-right: 2px solid #ff00ff; } }

@keyframes neonColors2px {
  0% {
    border-bottom: 2px solid #ff00ff;
    border-right: 2px solid #ff00ff; }
  25% {
    border-bottom: 2px solid #39ff14;
    border-right: 2px solid #39ff14; }
  50% {
    border-bottom: 2px solid #F7FF1A;
    border-right: 2px solid #F7FF1A; }
  75% {
    border-bottom: 2px solid #00FEFF;
    border-right: 2px solid #00FEFF; }
  100% {
    border-bottom: 2px solid #ff00ff;
    border-right: 2px solid #ff00ff; } }
