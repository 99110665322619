@import '../../../../Styles/variables';

.Description {
  display: block;
  backface-visibility: hidden;
  position: relative;

  &__Letters {
    display: inline-block;
    backface-visibility: hidden;
    
    &_portfolio {
      font-size: $normal-font-size;
      letter-spacing: $small-letter-spacing;
      line-height: 1.3;
      font-weight: bold;
      text-shadow: 0 0 2px #000;
    } 
  }
}