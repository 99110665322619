@import '../../Styles/variables';

.Layout {
  overflow: hidden;
  backface-visibility: hidden;

  @media only screen and (max-width: 1000px) and (max-height: 800px) and (orientation: landscape) and (min-resolution: 150dpi) {
    display: none;
  }
  
  &__Main {
    backface-visibility: hidden;
    perspective: 80rem;
  }
}